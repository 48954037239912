<template>
    <section>
        <!-- <div class="section-bg"></div> -->
        <div class="section-content">
            <div class="sc-inner" v-if="promo">
                <div class="sci-top">
                    <div class="st-head">
                        <p class="small-title">RSIA Melinda</p>
                        <h1 class="main-title">{{ promo.name }}</h1>
                    </div>
                    <div class="st-body">
                        <div class="st-img">
                            <img :src="'https://backend1.melindadev.com/' + promo.image" alt="melinda hospital" />
                        </div>
                        <div class="st-detail">
                            <div class="st-detail-head">
                                <!-- <h2>{{ promo.name }}</h2> -->
                            </div>
                            <div class="st-detail-body">
                                <div class="sdb-table">
                                    <div class="sdb-column">
                                        <div class="sdb-column-param">
                                            <p>Berlaku Mulai</p>
                                        </div>
                                        <div class="sdb-column-value">
                                            <p>{{ promo.date_start }}</p>
                                        </div>
                                    </div>
                                    <div class="sdb-column">
                                        <div class="sdb-column-param">
                                            <p>Berlaku Hingga</p>
                                        </div>
                                        <div class="sdb-column-value">
                                            <p>{{ promo.date_end }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sci-description">
                    <div class="sci-description-p">
                        <p>{{ promo.description }}</p>
                    </div>
                    <div class="sci-description-button">
                        <div class="sci-description-signup">
                            <router-link :to="'/paket/form/' + useRoute().params.slug">
                                <button>Daftar</button>
                            </router-link>
                        </div>
                        <div class="sci-description-get">
                            <a :href="'https://www.linktr.ee/melindahealthcare'" target="_blank">
                                <button>Admission Melinda Group</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="link-tree-icon" id="lticon-promo">
            <a href="https://www.linktr.ee/melindahealthcare" target="_blank">
                <img src="https://d1fdloi71mui9q.cloudfront.net/dqXVheyfR7iguNjEwy35_1BN3VpLQJBrWRaqi" alt="melinda hospital">
            </a>
        </div>
    </section>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { computed } from '@vue/reactivity';
    import { usePromoStore } from '@/stores/promo';
    import { onMounted, onUnmounted } from 'vue';
    onMounted(() => {
        document.getElementById('link-tree-icon').style.display = 'none';
        document.getElementById('lticon-promo').style.display = 'block';
    });
    onUnmounted(() => {
        document.getElementById('link-tree-icon').style.display = 'block';
    });
    const promo = computed(() => usePromoStore().promoDetail);
    usePromoStore().setPromoDetail(useRoute().params.slug);
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    /* .section-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 39rem;
        background: #FEF8F0;
        z-index: -1;
        transition: 0.2s;
    } */
    .small-title{
        color: #75C6EF;
        font-weight: 600;
        font-size: 1.125rem;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: 0.2s;
        line-height: 1.2;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .sc-inner{
        width: 100%;
        padding-top: 2rem;
        transition: 0.2s;
    }
    .sci-top{
        width: 100%;
        transition: 0.2s;
    }
    .st-body{
        display: flex;
        flex-wrap: wrap;
        padding-top: 2rem;
    }
    .st-img{
        width: 50%;
        margin-bottom: 2rem;
        transition: 0.2s;
    }
    .st-img img{
        width: auto;
        width: 100%;
        transition: 0.2s;
    }
    .st-detail{
        width: 50%;
        padding-left: 1rem;
        text-align: justify;
        transition: 0.2s;
    }
    .st-detail-head h2{
        margin: 0;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .sdb-table{
        display: flex;
        flex-wrap: wrap;
    }
    .sdb-column{
        width: 25%;
        transition: 0.2s;
        margin-bottom: 1rem;
        padding: 0.5rem;
    }
    .sdb-column-param{
        font-size: 1.1rem;
        font-weight: 700;
    }
    .sci-description{
        width: 100%;
        transition: 0.2s;
    }
    /* .sci-description button{
        border: none;
        border-radius: 50px;
        background: #75C6EF;
        color: #ffffff;
        padding: 0 1rem 0 1rem;
    } */
    .sci-description-button{
        display: flex;
        padding: 1rem 0 1rem 0;
    }
    .sci-description-button button{
        border: none;
        border-radius: 50px;
        background: #75C6EF;
        color: #ffffff;
        padding: 0.5rem 1rem 0.5rem 1rem;
        cursor: pointer;
    }
    .sci-description-get{
        padding: 0 0 0 1rem;
    }
    @media only screen and (max-width: 1500px){
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-bg{
            height: 31rem;
        }
        .main-title{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .main-title{
            font-size: 2rem;
        }
        .sci-top{
            width: 100%;
        }
        .st-img{
            width: 100%;
        }
        .st-img img{
            width: 100%;
        }
        .st-detail{
            width: 100%;
            padding-left: 0;
        }
        .sci-description{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 2rem;
            padding-left: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-top: 1rem;
        }
        .section-bg{
            height: 19rem;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sdb-column{
            width: 50%;
        }
        .sci-description{
            padding-top: 1rem;
        }
    }
    @media only screen and (max-width: 370px){
        .sdb-column{
            width: 100%;
        }
    }
</style>