<template>
    <section>
        <div class="section-head">
            <h3>RSIA Melinda</h3>
            <h2>Pelayanan dan Fasilitas</h2>
        </div>
        <div class="section-body">
            <div class="hdf-image" id="home-services-carousel">
                <div class="hdf-control">
                    <div class="hdf-control-button hcb-left-hs">
                        <span>{{ '<' }}</span>
                    </div>
                    <div class="hdf-control-button hcb-right-hs">
                        <span>{{ '>' }}</span>
                    </div>
                </div>
                <div class="hh-carousel home-services-carousel hhc-smooth">
                    <div class="hc-part" v-for="service in services" :key="service">
                        <div class="hcp-inner">
                            <div class="hcp-image">
                                <div class="img-cover"></div>
                                <img :src="service.img_url" alt="melinda care">
                            </div>
                            <div class="hcp-detail">
                                <h3>{{ service.name }}</h3>
                                <p>{{ wordsLimit(removeHTML(service.description), 100) }}</p>
                                <router-link :to="'/services/detail/' + service.slug">
                                    <button>Detail</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="hc-part" v-for="service in services" :key="service">
                        <div class="hcp-inner">
                            <div class="hcp-image">
                                <div class="img-cover"></div>
                                <img :src="service.img_url" alt="melinda care">
                            </div>
                            <div class="hcp-detail">
                                <h3>{{ service.name }}</h3>
                                <p>{{ wordsLimit(removeHTML(service.description), 100) }}</p>
                                <router-link :to="'/services/detail/' + service.slug">
                                    <button>Detail</button>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useHomeStore } from "../../stores/home";
    import { onMounted } from 'vue';
    import { wordsLimit, removeHTML } from "@/functions/general";
    const services = computed(() => useHomeStore().services);
    onMounted(() => {
        let isDown = false;
        let startX;
        let scrollLeft;
        const slider = document.querySelector('.home-services-carousel');
        const btleft = document.querySelector('.hcb-left-hs');
        const btright = document.querySelector('.hcb-right-hs');
        const sparent = slider.parentElement;
        const end = () => {
            isDown = false;
            slider.classList.add('hhc-smooth');
            slider.scrollLeft = Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth;
        }
        const start = (e) => {
            isDown = true;
            slider.classList.remove('hhc-smooth');
            startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        }
        const move = (e) => {
            if(!isDown) return;
            const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            const dist = (x - startX) * 1.9;
            slider.scrollLeft = scrollLeft - dist;
        }
        const goLeft = (e) => {
            e.preventDefault();
            slider.scrollLeft = (Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth) - sparent.scrollWidth;
        }
        const goRight = (e) => {
            e.preventDefault();
            slider.scrollLeft = (Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth) + sparent.scrollWidth;
        }
        const sresize = () => {
            slider.scrollLeft = 0;
        }
        (() => {
            slider.addEventListener('mousedown', start);
            slider.addEventListener('touchstart', start);
            slider.addEventListener('mousemove', move);
            slider.addEventListener('touchmove', move);
            slider.addEventListener('mouseleave', end);
            slider.addEventListener('mouseup', end);
            slider.addEventListener('touchend', end);
            slider.addEventListener('resize', sresize);
            btleft.addEventListener('click', goLeft);
            btright.addEventListener('click', goRight);
        })();
    });
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 0 10%;
    }
    .section-head{
        padding: 0 0.5% 0 0.5%;
    }
    .section-head h3{
        font-size: 1.1rem;
        font-family: poppins;
        color: var(--navy);
        margin: 0;
    }
    .section-head h2{
        margin: 1rem 0 1rem 0;
        font-size: 2rem;
        font-weight: 700;
        font-family: poppins;
        color: #1B4674;
    }
    .hdf-image{
        position: relative;
        width: 100%;
        display: flex;
        /* height: 37rem; */
        overflow: hidden;
        user-select: none;
        background: #ffffff;
    }
    .hh-carousel{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        transition: 0.2s;
    }
    .hhc-smooth{
        scroll-behavior: smooth;
    }
    .hc-part{
        position: relative;
        display: inline-block;
        min-width: 33.33%;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
    }
    .hc-part a{
        text-decoration: underline;
    }
    .hdf-control-button{
        position: absolute;
        z-index: 5;
        background: #ffffff;
        color: grey;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        border: 1px solid whitesmoke;
        border-radius: 37px;
        cursor: pointer;
        transition: 0.2s;
        line-height: 1;
    }
    .hdf-control-button:hover{
        background: whitesmoke;
    }
    .hcb-left-hs{
        top: 45%;
        left: 1rem;
    }
    .hcb-right-hs{
        top: 45%;
        right: 1rem;
    }
    .hcp-inner{
        position: relative;
        height: 100%;
        overflow: hidden;
        /* background: var(--primary); */
        border-radius: 10px;
    }
    .hcp-image{
        position: relative;
        /* display: flex; */
        /* justify-content: center; */
        width: 100%;
        /* height: 55%; */
        overflow: hidden;
        padding: 0.5rem;
        /* border: 1px solid green; */
        text-align: center;
    }
    .hcp-image img{
        width: 100%;
        /* max-width: 200px; */
        height: auto;
        /* max-height: 200px; */
        transition: 0.2s;
        border-radius: 5px;
    }
    .img-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .hcp-detail{
        height: 45%;
        padding: 1rem;
    }
    .hcp-detail h3{
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: 700;
        color: #1B4674;
    }
    .hcp-detail p{
        font-size: 14px;
        font-family: poppins-regular;
        color: #000000;
    }
    .hcp-detail button{
        border: none;
        border-radius: 50px;
        background: var(--navy);
        color: #ffffff;
        padding: 0.5rem 2rem 0.5rem 2rem;
        /* padding-left: 2rem;
        padding-right: 2rem; */
        cursor: pointer;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 4.5% 0 4.5%;
        }
        .hcp-image{
            /* height: 50%; */
        }
    }
    @media only screen and (max-width: 1050px){
        .hh-left{
            padding-top: 2rem;
            padding-right: 2rem;
        }
        .hh-left h1{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 1.5% 0 1.5%;
        }
        .section-head{
            padding: 0 1.5% 0 1.5%;
        }
        .hc-part{
            min-width: 50%;
        }
    }
    @media only screen and (max-width: 610px){
        .hc-part{
            min-width: 100%;
        }
    }
</style>