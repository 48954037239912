<template>
    <section>
        <div class="section-head">
            <h1>Event</h1>
            <p>Ikuti Event untuk mendapatkan berbagai macam manfaat</p>
        </div>
        <div class="section-body">
            <template v-if="events">
                <div class="section-body-inner" v-if="events.length > 0">
                    <div class="sbi-part" v-for="event in events" :key="event">
                        <router-link :to="'/event/detail/' + event.slug">
                            <div class="sbi-part-inner">
                                <div class="spi-image">
                                    <div class="img-cover"></div>
                                    <img :src="event.image_url" alt="melinda care">
                                </div>
                                <div class="spi-detail">
                                    <h2>{{ event.name }}</h2>
                                    <p>{{ wordsLimit(removeHTML(event.description), 91) }}</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="section-body-inner" v-else>
                    <div class="sbi-warning">
                        <p>Saat ini tidak ada event</p>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useEventStore } from '@/stores/event';
    import { wordsLimit, removeHTML, setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'RSIA Melinda - Event',
            description: 'Ikuti Event untuk mendapatkan berbagai macam manfaat'
        });
    });
    const events = computed(() => useEventStore().events);
    useEventStore().setEvents();
</script>

<style scoped>
    section{
        padding: 2.5rem 9% 2.5rem 9%;
    }
    .section-head{
        padding: 0 0.5rem 0 0.5rem;
    }
    .section-head h1{
        margin: 0 0 1rem 0;
        /* margin-top: 1rem;
        margin-bottom: 1rem; */
        font-size: 2rem;
        font-weight: 700;
        color: #1B4674;
    }
    .section-head p{
        font-size: 1rem;
        color: grey;
    }
    .section-body-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sbi-part{
        width: 25%;
        height: 27rem;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .sbi-part a{
        text-decoration: none;
    }
    .sbi-part-inner{
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.12);
        transition: 0.2s;
        height: 100%;
    }
    .sbi-part-inner:hover{
        /* transform: scale(1.01); */
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.12);
    }
    .spi-image{
        height: 50%;
        overflow: hidden;
    }
    .spi-image img{
        width: 100%;
    }
    .spi-detail{
        padding: 0.5rem;
        height: 50%;
    }
    .spi-detail h2{
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
        font-weight: 700;
        color: #1B4674;
    }
    .spi-detail p{
        color: grey;
    }
    .sbi-warning{
        text-align: center;
        width: 100%;
    }
    .sbi-warning p{
        font-style: italic;
        color: var(--softgrey);
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 4.5% 2.5rem 4.5%;
        }
    }
    @media only screen and (max-width: 1100px){
        .sbi-part{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 1.5% 2.5rem 1.5%;
        }
        .sbi-part{
            width: 50%;
            height: 23rem;
        }
    }
    @media only screen and (max-width: 450px){
        .sbi-part{
            width: 100%;
        }
    }
</style>