<template>
    <HomeTopComponent/>
    <HomeServicesComponent/>
    <HomePromoEventComponent/>
    <HomeRecommendationComponent/>
    <HomeArticlesComponent/>
</template>

<script setup>
    import HomeTopComponent from '@/components/home/HomeTopComponent.vue';
    import HomeServicesComponent from '@/components/home/HomeServicesComponent.vue';
    import HomePromoEventComponent from '@/components/home/HomePromoEventComponent.vue';
    import HomeRecommendationComponent from '@/components/home/HomeRecommendationComponent.vue';
    import HomeArticlesComponent from '@/components/home/HomeArticlesComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'RSIA Melinda Bandung',
            description: 'RSIA Melinda merupakan Rumah Sakit Ibu dan Anak. Kesehatan adalah kunci utama kecantikan dan kebahagiaan seorang wanita. Dengan tulus kami berkomitmen mewujudkan impian setiap wanita untuk tampil cantik, sehat dan bahagia.'
        });
    });
    // scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>