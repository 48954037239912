<template>
    <PromoFormComponent/>
</template>

<script setup>
    import PromoFormComponent from '@/components/promo/PromoFormComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>