import { usePromoStore } from "@/stores/promo";
import { showNotification, number_format } from "./general";
export function showEventnotif(title, message, milisecond){
    const date = new Date();
    const time = date.getTime();
    const nt = document.getElementById('eventnotif');
    const tt = document.getElementById('eventnotif-title');
    const mg = document.getElementById('eventnotif-message');
    nt.style.display = 'none';
    nt.setAttribute('time', time);
    setTimeout(function(){
        tt.innerHTML = title;
        mg.innerHTML = message;
        nt.style.display = 'block';
        setTimeout(function(){
            if(nt.getAttribute('time') == time){
                nt.style.display = 'none';
            }
        }, milisecond);
    }, 0);
}
export function hideEventnotif(){
    const nt = document.getElementsByClassName('eventnotif');
    for(var i = 0; i < nt.length; i++){
        nt[i].style.display = 'none';
    }
}
export function submitForm(){
    if(validateRequired('required') && validateEmail('email') && validatePhone('phone')){
        const name = document.getElementById('name');
        const phone = document.getElementById('phone');
        const address = document.getElementById('address');
        const email = document.getElementById('email');
        const pregage = document.getElementById('pregage');
        const kidage = document.getElementById('kidage');
        usePromoStore().setWebinar({name: name.value, phone: phone.value, email: email.value, age_pregnant: pregage.value, age_child: kidage.value, address: address.value });
    }else{
        showNotification('', 'Silakan ikuti petunjuk yang ada pada Form', 5000);
    }
}
export function submitLactation(){
    if(validateRequired('required') && validateEmail('email') && validatePhone('phone') && validateRadioRequired('radio-required')){
        const email = document.getElementById('input-laktasi-' + 1);
        const phone = document.getElementById('input-laktasi-' + 14);
        const name = document.getElementById('input-laktasi-' + 2);
        const bdmom = document.getElementById('input-laktasi-' + 3);
        const childnum = document.getElementById('input-checkbox-' + 4);
        // const childnumval = [];
        var childnumval = '';
        for(var i = 0; i < childnum.children.length; i++){
            if(childnum.children[i].children[0].checked){
                // childnumval.push(childnum.children[i].children[0].value);
                childnumval = childnumval + childnum.children[i].children[0].value + ',';
            }
        }
        childnumval = childnumval.slice(0, -1);
        // console.log(childnumval);
        const pc_age = document.getElementById('input-laktasi-' + 5);
        const breastfeed_history = document.getElementById('input-radio-' + 6);
        // const bhval = [];
        var bhval = '';
        for(i = 0; i < breastfeed_history.children.length; i++){
            if(breastfeed_history.children[i].children[0].checked){
                // bhval.push(breastfeed_history.children[i].children[0].value);
                bhval = bhval + breastfeed_history.children[i].children[0].value + ',';
            }
        }
        bhval = bhval.slice(0, -1);
        // console.log(bhval);
        const birth_process = document.getElementById('input-radio-' + 7);
        // const bpval = [];
        var bpval = '';
        for(i = 0; i < birth_process.children.length; i++){
            if(birth_process.children[i].children[0].checked){
                // bpval.push(birth_process.children[i].children[0].value);
                bpval = bpval + birth_process.children[i].children[0].value + ',';
            }
        }
        bpval = bpval.slice(0, -1);
        // console.log(bpval);
        const birth_place = document.getElementById('input-laktasi-' + 8);
        const birth_weight = document.getElementById('input-laktasi-' + 9);
        const baby_weight = document.getElementById('input-laktasi-' + 10);
        const complaint = document.getElementById('input-laktasi-' + 11);
        const lactation_session = document.getElementById('input-radio-' + 12);
        // const lsval = [];
        var lsval = '';
        for(i = 0; i < lactation_session.children.length; i++){
            if(lactation_session.children[i].children[0].checked){
                // lsval.push(lactation_session.children[i].children[0].value);
                lsval = lsval + lactation_session.children[i].children[0].value + ',';
            }
        }
        lsval = lsval.slice(0, -1);
        // console.log(lsval);
        const params = {
            group_id: 2,
            name: name.value,
            email: email.value,
            phone: phone.value,
            age_pregnant: pc_age.value,
            birthdate_mom: bdmom.value,
            pregnant_number: childnumval,
            breastfeeding_history: bhval,
            givebirth_naturally: bpval,
            givebirth_place: birth_place.value, 
            baby_birth_weight: birth_weight.value,
            baby_now_weight: baby_weight.value,
            complaint: complaint.value,
            lactation_session: lsval
        };
        // console.log(params);
        usePromoStore().setWebinar(params);
    }else{
        showNotification('', 'Silakan ikuti petunjuk yang ada pada Form', 5000);
    }
    // usePromoStore().setWebinar(
    //     {
    //         group_id: 2, 
    //         name: 'Name', 
    //         email: 'email@mail.com', 
    //         age_pregnant: 1, 
    //         birthdate_mom: '1998-05-20', 
    //         pregnant_number: '1', 
    //         breastfeeding_history: 'Asi Hanya 6 bulan', 
    //         givebirth_naturally: 'normal', 
    //         givebirth_place: 'RSIA Melinda', 
    //         baby_birth_weight: '2.8',
    //         baby_now_weight: '8',
    //         complaint: 'ASI Tersendat',
    //         lactation_session: 'mengikuti keduanya'
    //     }
    // );
}
export function validateRequired(rc){
    const vd = document.getElementsByClassName(rc);
    var missing = 0;
    for(var i = 0; i < vd.length; i++){
        if(!vd[i].value){
            missing = missing + 1;
            vd[i].parentElement.nextElementSibling.children[0].innerHTML = 'Ini diperlukan';
        }
    }
    if(missing == 0){
        return true;
    }
    return false;
}
export function validateRadioRequired(rc){
    const vd = document.getElementsByClassName(rc); // radio-required
    var incheck = 0;
    for(var i = 0; i < vd.length; i++){
        var checked = 0;
        for(var j = 0; j < vd[i].children.length; j++){
            // console.log(vd[i].children[j].children[0]);
            if(vd[i].children[j].children[0].checked){
                checked = checked + 1;
            }
        }
        if(checked > 0){
            vd[i].nextElementSibling.children[0].innerHTML = '';
            incheck = incheck + 1;
        }else{
            vd[i].nextElementSibling.children[0].innerHTML = 'Ini diperlukan';
        }
    }
    if(incheck == vd.length){
        return true;
    }
    return false;
}
export function validateEmail(ec){
    const ei = document.getElementsByClassName(ec);
    for(var i = 0; i < ei.length; i++){
        if(ei[i].value){
            var foul = 0;
            const eival = ei[i].value.trim();
            if(!eival.match(' ')){
                const iv = eival.split('@');
                if(iv.length == 2){
                    const iv1 = iv[1].split('.');
                    if(iv1[1]){
                        if(iv1[1].length > 1){
                            return true;
                        }
                    }
                }else{
                    foul = 1;
                }
            }else{
                foul = 1;
            }
            if(foul > 0){
                ei[i].parentElement.nextElementSibling.children[0].innerHTML = 'Email harus benar';
            }
        }
    }
    return false;
}
export function validatePhone(cp){
    const ip = document.getElementsByClassName(cp);
    var count = 0;
    for(var i = 0; i < ip.length; i++){
        if(ip[i].value){
            if(ip[i].value.length > 5){
                if(ip[i].value.length > 14){
                    ip[i].parentElement.nextElementSibling.children[0].innerHTML = 'Nomor Telepon tidak valid';
                    count = count + 1;
                }
            }else{
                count = count + 1;
                ip[i].parentElement.nextElementSibling.children[0].innerHTML = 'Nomor Telepon tidak valid';
            }
        }
    }
    if(count == 0){
        return true;
    }
    return false;
}
export function clearWarning(event){
    event.srcElement.parentElement.nextElementSibling.children[0].innerHTML = '';
}
export function formSubmit(){
    const form = usePromoStore().eventForm;
    const input = form.fields;
    var required = 0;
    var reqfilled = 0;
    var emailfalse = 0;
    var phonefalse = 0;
    var filefalse = 0;
    for(var i = 0; i < input.length; i++){
        const thisinput = document.getElementById('input-' + input[i].name);
        if(input[i].type == 'email'){ // validate email
            if(thisinput.value){
                if(!emailValidation('input-' + input[i].name)){
                    emailfalse = emailfalse + 1;
                    document.getElementById('warning-' + input[i].name).innerHTML = '<small>' + input[i].label + ' harus benar</small>';
                }
            }
        }
        if(input[i].type == 'phone'){ // validate phone
            if(thisinput.value){
                if(!phoneValidation('input-' + input[i].name)){
                    phonefalse = phonefalse + 1;
                    document.getElementById('warning-' + input[i].name).innerHTML = '<small>' + input[i].label + ' harus benar</small>';
                }
            }
        }
        if(input[i].type == 'file'){ // validate file
            if(thisinput.value){
                const [file] = thisinput.files;
                console.log(file.size);
                // if(!phoneValidation('input-' + input[i].name)){
                //     filefalse = filefalse + 1;
                //     document.getElementById('warning-' + input[i].name).innerHTML = '<small>' + input[i].label + ' harus benar</small>';
                // }
            }
        }
        if(input[i].required){ // validate required
            required = required + 1;
            if(input[i].type == 'hidden' || input[i].type == 'text' || input[i].type == 'number' || input[i].type == 'email' || input[i].type == 'date' || input[i].type == 'phone' || input[i].type == 'file'){
                if(thisinput.value){
                    reqfilled = reqfilled + 1;
                }else{
                    document.getElementById('warning-' + input[i].name).innerHTML = '<small>' + input[i].label + ' diperlukan</small>';
                }
            }
            if(input[i].type == 'checkbox' || input[i].type == 'radio' || input[i].type == 'checkbox-multi'){
                var threq = 0;
                const thiselement = document.getElementById('element-' + input[i].name);
                for(var j = 0; j < thiselement.children.length; j++){
                    if(thiselement.children[j].children[0].checked){
                        threq = threq + 1;
                    }
                }
                if(threq > 0){
                    reqfilled = reqfilled + 1;
                }else{
                    document.getElementById('warning-' + input[i].name).innerHTML = '<small>' + input[i].label + ' diperlukan</small>';
                }
            }
            if(input[i].type == 'checkbox-price'){
                threq = 0;
                const thiselement = document.getElementById('element-' + input[i].name);
                for(j = 0; j < thiselement.children.length; j++){
                    if(thiselement.children[j].children[0].checked){
                        threq = threq + 1;
                    }
                }
                if(threq > 0){
                    reqfilled = reqfilled + 1;
                }else{
                    document.getElementById('warning-' + input[i].name).innerHTML = '<small>' + input[i].label + ' diperlukan</small>';
                }
            }
            if(input[i].type == 'select'){
                threq = 0;
                const thiselement = document.getElementById('element-' + input[i].name);
                if(thiselement.children[0].value != ''){
                    threq = threq + 1;
                }
                if(threq > 0){
                    reqfilled = reqfilled + 1;
                }else{
                    document.getElementById('warning-' + input[i].name).innerHTML = '<small>' + input[i].label + ' diperlukan</small>';
                    // console.log(input[i].name);
                    // console.log(document.getElementById('warning-' + input[i].name));
                }
                // console.log(thiselement.children[0].value);
            }
        }
    }
    if(required == reqfilled && emailfalse == 0 && phonefalse == 0 && filefalse == 0){
        const params = {};
        // const params = [];
        const fd = new FormData();
        for(i = 0; i < input.length; i++){
            const thisinput = document.getElementById('input-' + input[i].name);
            const thisname = input[i].name;
            var thisvalue = '';
            if(input[i].type == 'hidden' || input[i].type == 'text' || input[i].type == 'number' || input[i].type == 'email' || input[i].type == 'phone' || input[i].type == 'date'){
                // params.push({[thisname]: thisinput.value});
                params[thisname] = thisinput.value;
                fd.append(thisname, thisinput.value);
            }
            if(input[i].type == 'checkbox'){
                const thiselement = document.getElementById('element-' + input[i].name);
                for(j = 0; j < thiselement.children.length; j++){
                    if(thiselement.children[j].children[0].checked){
                        thisvalue = thisvalue + thiselement.children[j].children[0].value + ',';
                    }
                }
                // params.push({[thisname]: thisvalue.slice(0, -1)});
                params[thisname] = thisvalue.slice(0, -1);
                fd.append(thisname, thisvalue.slice(0, -1));
            }
            if(input[i].type == 'checkbox-multi'){
                const thiselement = document.getElementById('element-' + input[i].name);
                const arrayValue = [];
                for(j = 0; j < thiselement.children.length; j++){
                    
                    if(thiselement.children[j].children[0].checked){
                        fd.append(`${thisname}[${j}][value]`, thiselement.children[j].children[0].value);
                        var options = [];
                        if(thiselement.children[j].nextElementSibling.classList.contains('sbi-checkbox-children')){
                            for(var k = 0; k < thiselement.children[j].nextElementSibling.children.length; k++){
                                if(thiselement.children[j].nextElementSibling.children[k].children[0].checked){
                                    options.push(thiselement.children[j].nextElementSibling.children[k].children[0].value);
                                    fd.append(`${thisname}[${j}][options][]`,thiselement.children[j].nextElementSibling.children[k].children[0].value)
                                }
                            }
                        }
                        arrayValue.push({value: thiselement.children[j].children[0].value, options: options});
                        thisvalue = arrayValue;
                        // thisvalue = thisvalue + thiselement.children[j].children[0].value + ',';
                    }
                }
                // params.push({[thisname]: thisvalue.slice(0, -1)});
                // for(var l = 0; l < thisvalue.length; l++){
                //     fd.append(thisname + '[]', JSON.parse(JSON.stringify(thisvalue[l])));
                // }
                params[thisname] = thisvalue;
            }
            if(input[i].type == 'checkbox-price'){
                const thiselement = document.getElementById('element-' + input[i].name);
                for(j = 0; j < thiselement.children.length; j++){
                    if(thiselement.children[j].children[0].checked){
                        thisvalue = thisvalue + thiselement.children[j].children[0].value + ',';
                    }
                }
                fd.append(thisname, thisvalue.slice(0, -1));
            }
            if(input[i].type == 'radio'){
                const thiselement = document.getElementById('element-' + input[i].name);
                for(j = 0; j < thiselement.children.length; j++){
                    if(thiselement.children[j].children[0].checked){
                        thisvalue = thiselement.children[j].children[0].value;
                    }
                }
                // params.push({[thisname]: thisvalue});
                params[thisname] = thisvalue;
                fd.append(thisname, thisvalue);
                // console.log(thiselement);
            }
            if(input[i].type == 'file'){
                // const el = document.getElementById('form-' + input[i].name);
                // const fd = new FormData();
                const [file] = thisinput.files;
                // fd.append('hello', file);
                // console.log(el);
                // console.log(fd.get('hello'));
                // params.push({[thisname]: file});
                params[thisname] = file;
                fd.append(thisname, file);
            }
            if(input[i].type == 'select'){
                const thiselement = document.getElementById('element-' + input[i].name);
                thisvalue = thiselement.children[0].value;
                console.log(thiselement.children[0].value);
                fd.append(thisname, thiselement.children[0].value);
                // ====================================
                // for(j = 0; j < thiselement.children.length; j++){
                //     if(thiselement.children[j].children[0].checked){
                //         thisvalue = thiselement.children[j].children[0].value;
                //     }
                // }
                // params.push({[thisname]: thisvalue});
                params[thisname] = thisvalue;
                // console.log(thiselement);
            }
        }
        // console.log(params);
        usePromoStore().setWebinar(form.slug, fd, params);
    }else{
        showNotification('', 'Silakan ikuti petunjuk yang ada pada Form, lihat keterangan di bawah field yang salah', 5000);
        // console.log('rq : ' + required + ' - rf : ' + reqfilled);
    }
}
export function emailValidation(id){
    const ei = document.getElementById(id);
    if(ei.value){
        // var foul = 0;
        const eival = ei.value.trim();
        if(!eival.match(' ')){
            const iv = eival.split('@');
            if(iv.length == 2){
                const iv1 = iv[1].split('.');
                if(iv1[1]){
                    if(iv1[1].length > 1){
                        return true;
                    }
                }
            }
        }
    }
    return false;
}
export function phoneValidation(id){
    const ip = document.getElementById(id);
    if(ip.value){
        if(ip.value.length > 5 && ip.value.length < 15){
            return true;
        }
    }
    return false;
}
export function warningClear(event){
    event.srcElement.parentElement.nextElementSibling.innerHTML = '';
    // console.log(event);
}
export function imgPreview(id){
    const input = document.getElementById(id);
    // console.log(input.value);
    const [file] = input.files;
    if(file){
        input.previousElementSibling.innerHTML = '<img src="' + URL.createObjectURL(file) + '" style="margin: 0 0 1rem 0; width: 100%;" />';
        input.parentElement.nextElementSibling.innerHTML = '';
        // console.log(file);
        // for(var i = 0; i < imgpv.length; i++){
        //     imgpv[i].children[0].src = URL.createObjectURL(file);
        // }
    }
}
export function cpCalculate(name, discounts){
    const el = document.getElementById('element-' + name);
    const de = document.getElementById('eldisc-' + name);
    const et = document.getElementById('eltotal-' + name);
    const discount = discounts[0];
    var checked = 0;
    var discval = 0;
    var total = 0;
    for(var i = 0; i < el.children.length; i++){
        if(el.children[i].children[0].checked){
            total = parseInt(total) + parseInt(el.children[i].children[2].value);
            checked = parseInt(checked) + 1;
        }
    }
    if(checked >= discount.min_class){
        if(discount.value > 0){
            if(discount.schema == 'percent'){
                if(discount.value <= 100){
                    discval = (total / 100) * discount.value;
                    total = total - discval;
                }
            }else if(discount.schema == 'flat'){
                discval = discount.value;
                total = total - discval;
            }
        }
    }
    de.children[0].children[1].children[0].innerHTML = 'Rp' + number_format(discval, 0, ',', '.');
    et.children[0].children[1].children[0].innerHTML = 'Rp' + number_format(total, 0, ',', '.');
    el.nextElementSibling.innerHTML = '';
}
export function selectDefaultContact(){
    const contacts = usePromoStore().contacts;
    for(var i = 0; i < contacts.length; i++){
        if(contacts[i].selected){
            window.open('https://wa.me/' + contacts[i].phone + '?text=' + contacts[i].text)
        }
    }
}
export function inputParentChecked(e){
    if(e.srcElement.parentElement?.nextElementSibling?.classList.contains('sbi-checkbox-children')){
        if(e.srcElement.checked){
            e.srcElement.parentElement.nextElementSibling.style.maxHeight = e.srcElement.parentElement.nextElementSibling.scrollHeight + 'px';
        }else{
            e.srcElement.parentElement.nextElementSibling.style.maxHeight = 0;
        }
    }
}