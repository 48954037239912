<template>
    <section>
        <!-- <div class="section-bg"></div> -->
        <div class="section-top" v-if="room">
            <div class="st-image">
                <RoomCarouselComponent/>
            </div>
            <div class="st-detail">
                <div class="st-detail-head">
                    <h2>{{ room.subtitle }}</h2>
                    <h1>{{ room.title }}</h1>
                </div>
                <div class="st-detail-body">
                    <div class="sdb-part" v-for="facility in room.checklist" :key="facility">
                        <div class="sdb-icon">
                            <!-- <div class="cream-check">
                                <img src="../../assets/images/icons/creamcheck.png" alt="rsia melinda"/>
                            </div> -->
                            <div class="primary-check">
                                <img src="../../assets/images/icons/primarycheck.png" alt="rsia melinda"/>
                            </div>
                        </div>
                        <div class="sdb-text">
                            <span>{{ facility.name }}</span>
                            <small class=""> ({{ facility.qty }})</small>
                        </div>
                    </div>
                </div>
                <div class="st-detail-footer">
                    <router-link to="/rooms">
                        <span>Find Another Room</span>
                    </router-link>
                </div>
            </div>
        </div>
        <RoomDetailSkeleton v-else/>
    </section>
</template>

<script setup>
    import RoomDetailSkeleton from '@/components/skeletons/sections/RoomDetailSkeleton.vue';
    import { useRoute } from 'vue-router';
    import { computed } from '@vue/reactivity';
    import { useRoomStore } from '@/stores/room';
    import RoomCarouselComponent from '@/components/rooms/RoomCarouselComponent.vue';
    const room = computed(() => useRoomStore().roomDetail);
    useRoomStore().setRoomDetail(useRoute().params.slug);
</script>

<style scoped>
    section{
        position: relative;
        padding: 0 0 2.5rem 0;
        height: 700px;
        transition: 0.2s;
        /* background: var(--white); */
        /* z-index: -1; */
    }
    .section-bg{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25%;
        background: var(--cream-bg);
        z-index: -1;
        transition: 0.2s;
    }
    .section-top{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        padding: 2.5% 10% 0 10%;
        transition: 0.2s;
        /* border: 1px solid green; */
        z-index: 5;
    }
    .st-image{
        width: 50%;
        height: 100%;
        transition: 0.2s;
        /* border: 1px solid red; */
    }
    .st-image-top{
        width: 100%;
        height: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 0 0;
        overflow: hidden;
        background: #000000;
    }
    .st-image-top img{
        width: 100%;
        transition: 0.2s;
    }
    .st-image-bottom{
        display: flex;
        width: 100%;
        height: 15%;
        padding: 0.5rem 0 0.5rem 0;
    }
    .sib-left{
        width: 75%;
        height: 100%;
        display: flex;
    }
    .sib-left-part{
        width: 33.33%;
        height: 100%;
        overflow: hidden;
        padding: 0 0.5rem 0 0;
    }
    .sib-left-part img{
        width: 100%;
        transition: 0.2s;
    }
    .sib-right{
        width: 25%;
        height: 100%;
        border: 1px solid var(--softgrey);
        background: #00000050;
    }
    .st-detail{
        position: relative;
        width: 50%;
        padding: 0 0 0 1rem;
        color: var(--navy);
        transition: 0.2s;
    }
    .st-detail-head h2{
        font-size: 1rem;
        color: var(--primary);
        margin: 0;
        transition: 0.2s;
    }
    .st-detail-head h1{
        font-size: 2rem;
        font-family: poppins;
        margin: 1rem 0 1rem 0;
        transition: 0.2s;
        color: var(--navy);
    }
    .st-detail-body{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .sdb-part{
        width: 50%;
        display: flex;
        padding: 0.5rem 0.5rem 0.5rem 0;
        transition: 0.2s;
        /* background: var(--navy); */
    }
    .sdb-icon{
        width: 10%;
    }
    /* .primary-check{
        display: none;
    } */
    .sdb-text{
        width: 90%;
    }
    .st-detail-footer{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;
    }
    .st-detail-footer a{
        text-decoration: none;
        color: var(--navy);
        background: var(--cream);
        padding: 1rem 2rem 1rem 2rem;
        border-radius: 10rem;
        font-size: 1.5rem;
        font-weight: 700;
    }
    @media only screen and (max-width: 1500px){
        section{
            height: 700px;
            padding: 0 0 2.5rem 0;
        }
        .section-top{
            padding: 5rem 5% 0 5%;
        }
        .st-image-top img{
            width: auto;
            height: 100%;
        }
    }
    @media only screen and (max-width: 1200px){
        /* section{
            height: 600px;
        } */
    }
    @media only screen and (max-width: 1000px){
        section{
            height: auto;
        }
        .section-bg{
            height: 200px;
            display: none;
        }
        .st-image{
            width: 100%;
            height: 600px;
        }
        .st-detail{
            width: 100%;
            color: var(--navy);
            padding: 2rem 0 0 0;
        }
        .st-detail-head h2{
            color: var(--primary);
        }
        .sdb-part{
            background: unset;
        }
        /* .cream-check{
            display: none;
        } */
        /* .primary-check{
            display: block;
        } */
        .st-detail-footer{
            position: relative;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 0 0 2.5rem 0;
        }
        .section-top{
            padding: 2.5rem 2.5% 0 2.5%;
        }
        .st-image{
            height: 600px;
        }
        .st-detail-head h1{
            font-size: 2.5rem;
        }
    }
    @media only screen and (max-width: 490px){
        .sib-left-part{
            border-right: 5px solid var(--white);
        }
        .sib-left-part img{
            width: auto;
            height: 100%;
        }
    }
    @media only screen and (max-width: 450px){
        .st-detail-head h1{
            font-size: 1.5rem;
        }
        .sdb-part{
            width: 100%;
        }
        .st-detail-footer a{
            font-size: 1rem;
        }
    }
</style>