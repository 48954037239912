import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { showNotification } from "@/functions/general.js";

export const useContactStore = defineStore("contact", {
    state: () => ({
        // ==================== states used on contact page and its relation
        // allRooms: null,
        // rooms: null,
        // searchBar: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        // categories: null,
        // roomsShow: null,
        // layouts: null,
        // roomDetail: null,
        header: null,
        contactCard: null,
        contactData: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on contact page and its relation
        async setContact(){
            try{
                const header = await axiosClient.get(`page-header/contact`);
                const rescard = await axiosClient.get(`page-header/contact_card`);
                const resdata = await axiosClient.get(`contact-us`);
                this.header = header.data.page_header;
                this.contactCard = rescard.data.page_header;
                this.contactData = resdata.data;
                // for(var i = 0; i < this.allRooms.length; i++){
                //     this.allRooms[i].slug = createSlug(this.allRooms[i].title);
                // }
                // this.rooms = this.allRooms;
                // this.showRooms(6);
                // console.log(this.contactData);
            }catch(error){
                console.log(error);
            }
        },
        async submitForm(params){
            try{
                const response = await axiosClient.post(`contact-us/messages`, params);
                if(response.status == 200){
                    showNotification('', response.data.message, 2000, null);
                    setTimeout(function(){
                        location.reload();
                    }, 2000);
                }
                console.log(response);
            }catch(error){
                console.log(error);
            }
        }
    }
});