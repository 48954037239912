<template>
    <CareerDetailComponent/>
</template>

<script setup>
    import CareerDetailComponent from '@/components/careers/CareerDetailComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Kenali Kami Lebih Dekat',
            description: 'RSIA Melinda Bandung didirikan pada tahun 2004 oleh dr, Susan Melinda, Sp.OG. Kami memahami Ibu dan anak agar mendapatkan pelayanan kesehatan yang baik dengan penuh kasih sayang.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>






