<template>
    <servicesTopComponent/>
    <ServicesComponent/>
</template>

<script setup>
    import servicesTopComponent from '@/components/services/ServicesTopComponent.vue';
    import ServicesComponent from '@/components/services/ServicesComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Layanan Kesehatan Kami',
            description: 'RSIA Melinda memiliki fasilitas kesehatan yang inovatif yang menyediakan layanan spesialis dan sub-spesialis, peralatan medis yang sudah sesuai standar, dengan teknologi modern.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>
