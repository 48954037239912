<template>
    <ServiceDetailComponent/>
</template>

<script setup>
    import ServiceDetailComponent from '@/components/services/ServiceDetailComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>