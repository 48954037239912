<template>
    <HomeTopOptimizedComponent v-if="banner"/>
    <HomeTopCarouselSkeleton v-else/>
</template>

<script setup>
    import HomeTopOptimizedComponent from '@/components/home/HomeTopOptimizedComponent.vue';
    import HomeTopCarouselSkeleton from '@/components/skeletons/sections/HomeTopCarouselSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useHomeStore } from '@/stores/home';
    const banner = computed(() => useHomeStore().topBanner);
    useHomeStore().setTopBanner();
</script>

<style scoped>
    /*  */
</style>