import { defineStore } from "pinia";
import axiosClient from "../axios.js";
// import { servicesLayout } from "@/data/layouts.js";
import { createSlug, setPageData, wordsLimit, removeHTML } from "@/functions/general.js";

export const useRoomStore = defineStore("room", {
    state: () => ({
        // ==================== states used on rooms page and its relation
        allRooms: null,
        rooms: null,
        searchBar: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        categories: null,
        roomsShow: null,
        layouts: null,
        roomDetail: null,
        roomHeader: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on rooms page and its relation
        async setRooms(){
            try{
                // this.layouts = servicesLayout;
                const response = await axiosClient.get(`room`);
                const header = await axiosClient.get(`page-header/room`);
                this.allRooms = response.data;
                // this.categories = response.data.categories;
                this.roomHeader = header.data.page_header;
                for(var i = 0; i < this.allRooms.length; i++){
                    this.allRooms[i].slug = createSlug(this.allRooms[i].title);
                }
                this.rooms = this.allRooms;
                this.showRooms(6);
                // console.log(this.rooms);
            }catch(error){
                console.log(error);
            }
        },
        async setRoomDetail(slug){
            if(!this.allRooms){
                await this.setRooms();
            }
            this.roomDetail = null;
            var roomId;
            for(var i = 0; i < this.allRooms.length; i++){
                if(this.allRooms[i].slug == slug){
                    roomId = this.allRooms[i].id;
                }
            }
            try{
                const response = await axiosClient.get(`room/` + roomId);
                const comment = await axiosClient.get(`comment/room`);
                // const footer = await axiosClient.get(`footer_service`);
                this.roomDetail = response.data;
                this.roomDetail.comment = comment.data.comment;
                // this.roomDetail.footer = footer.data.data;
                setPageData({
                    title: this.roomDetail.title,
                    description: wordsLimit(removeHTML(this.roomDetail.comment.comment), 200)
                });
                // console.log(this.roomDetail);
            }catch(error){
                console.log(error);
            }
        },
        showRooms(limit){
            this.roomsShow = this.rooms.slice(0, limit);
        },
        moreRooms(){
            const ln = this.roomsShow.length;
            this.showRooms((ln + 6));
        },
        searchbarSelect(category, slug){
            this.searchBar.select.title = category;
            this.searchBar.select.value = slug;
            this.searchRoom();
        },
        searchbarInput(value){
            this.searchBar.input.value = value;
            this.searchRoom();
        },
        searchRoom(){
            var newsd = [];
            if(this.searchBar.select.value != 'all'){ // if selected specialist
                for(var i = 0; i < this.allRooms.length; i++){
                    if(this.allRooms[i].category.name == this.searchBar.select.title){
                        newsd.push(this.allRooms[i]);
                    }
                }
            }else{
                newsd = this.allRooms;
            }
            if(this.searchBar.input.value){
                var result = [];
                var inval = new RegExp(this.searchBar.input.value, 'gi');
                for(i = 0; i < newsd.length; i++){
                    if(newsd[i].name.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.rooms = result;
            }else{
                this.rooms = newsd;
            }
            this.showRooms(6);
        }
    }
});