import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import axiosMain from "../axiosmain.js";
import { createSlug } from "@/functions/general.js";

export const useHomeStore = defineStore("home", {
    state: () => ({
        // ==================== states used on home page and its relation
        topBanner: null,
        services: null,
        recommendation: null,
        articles: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on home page and its relation
        async setTopBanner(){
            try{
                const response = await axiosClient.get(`home/banner`);
                this.topBanner = response.data.carousel1;
                this.recommendation = {
                    head: {
                        title: response.data.title
                    }, 
                    body: {
                        kid: {
                            title: response.data.title_vertical, 
                            image: response.data.images_vertical,
                            link: response.data.link_vertical
                        }, 
                        family: {
                            title: response.data.title_horizontal, 
                            image: response.data.images_horizontal,
                            link: response.data.link_horizontal
                        }
                    }
                };
                this.setHomeData();
                // console.log(this.topBanner);
            }catch(error){
                console.log(error);
            }
        },
        async setHomeData(){
            try{
                const response = await axiosClient.get(`home`);
                this.services = response.data.services;
                for(var i = 0; i < this.services.length; i++){
                    this.services[i].slug = createSlug(this.services[i].name);
                }
                const rsarticle = await axiosMain.get(`article/content`);
                // this.rooms = response.data.rooms;
                this.articles = rsarticle.data.data.slice(0, 3);
                console.log(rsarticle);
                // setPageData({
                //     title: 'RSIA Melinda Bandung',
                //     description: 'RSIA Melinda Bandung merupakan Rumah Sakit Ibu dan Anak. Kesehatan adalah kunci utama kecantikan dan kebahagiaan seorang wanita. Dengan tulus kami berkomitmen mewujudkan impian setiap wanita untuk tampil cantik, sehat dan bahagia.'
                // });
            }catch(error){
                console.log(error);
            }
        }
    }
});