<template>
    <div v-if="events">
        <div v-if="events.length > 0">
            <div class="hdf-image" id="event-home-carousel">
                <div class="hdf-control">
                    <div class="hdf-control-button hcb-top-event">
                        <span>{{ '<' }}</span>
                    </div>
                    <div class="hdf-control-button hcb-right-event">
                        <span>{{ '>' }}</span>
                    </div>
                </div>
                <!-- <div class="fullscreen-button" @click="fullScreen('hdf-image');">[fullscreen]</div> -->
                <div class="hh-carousel vertical-carousel hhc-smooth">
                    <div class="hc-part" v-for="event in events" :key="event">
                        <div class="hcp-inner">
                            <div class="hcp-image">
                                <!-- <div class="img-cover"></div> -->
                                <img :src="event.image_url" alt="rsia melinda" />
                            </div>
                            <div class="hcp-detail">
                                <div class="hcp-detail-inner">
                                    <h3>{{ event.name }}</h3>
                                    <p>
                                        <span class="hdi-text">{{ wordsLimit(removeHTML(event.description), 75) }}</span>
                                        <router-link :to="'/event/detail/' + event.slug">
                                            <button>Detail</button>
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <p style="text-align: center; font-style: italic;">Saat ini tidak ada Event yang aktif</p>
        </div>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { computed } from '@vue/reactivity';
    import { useEventStore } from '@/stores/event';
    import { wordsLimit, removeHTML } from '@/functions/general';
    const events = computed(() => useEventStore().events);
    onMounted(() => {
        if(document.getElementById('event-home-carousel')){
            let isDown = false;
            let startY;
            let scrollTop;
            const slider = document.querySelector('.vertical-carousel');
            const btleft = document.querySelector('.hcb-top-event');
            const btright = document.querySelector('.hcb-right-event');
            // const schildren = slider.children;
            // const sparent = slider.parentElement;
            const end = () => {
                isDown = false;
                slider.classList.add('hhc-smooth');
                slider.scrollTop = Math.round(slider.scrollTop / slider.children[0].scrollHeight) * slider.children[0].scrollHeight;
                // console.log('ST : ' + slider.scrollTop + ' - OT : ' + slider.scrollHeight);
            }
            const start = (e) => {
                // e.preventDefault();
                isDown = true;
                slider.classList.remove('hhc-smooth');
                startY = e.pageY || e.touches[0].pageY - slider.offsetTop;
                scrollTop = slider.scrollTop;
            }
            const move = (e) => {
                if(!isDown) return;
                // e.preventDefault();
                const y = e.pageY || e.touches[0].pageY - slider.offsetTop;
                const dist = (y - startY) * 1.9;
                slider.scrollTop = scrollTop - dist;
                // console.log(e);
            }
            const goLeft = (e) => {
                e.preventDefault();
                // goTop();
                slider.scrollTop = (Math.round(slider.scrollTop / slider.children[0].scrollHeight) * slider.children[0].scrollHeight) - slider.children[0].scrollHeight;
            }
            const goRight = (e) => {
                e.preventDefault();
                // goTop();
                slider.scrollTop = (Math.round(slider.scrollTop / slider.children[0].scrollHeight) * slider.children[0].scrollHeight) + slider.children[0].scrollHeight;
            }
            const sresize = () => {
                slider.scrollTop = 0;
            }
            // const goTop = () => {
            //     document.body.scrollTop = 0;
            //     document.documentElement.scrollTop = 0;
            // }
            (() => {
                slider.addEventListener('mousedown', start);
                slider.addEventListener('touchstart', start);
                slider.addEventListener('mousemove', move);
                slider.addEventListener('touchmove', move);
                slider.addEventListener('mouseleave', end);
                slider.addEventListener('mouseup', end);
                slider.addEventListener('touchend', end);
                slider.addEventListener('resize', sresize);
                btleft.addEventListener('click', goLeft);
                btright.addEventListener('click', goRight);
            })();
        }
    });
    useEventStore().setEvents();
</script>

<style scoped>
    .hdf-image{
        position: relative;
        width: 100%;
        display: flex;
        height: 37rem;
        overflow: hidden;
        user-select: none;
        /* background: #ffffff; */
        transition: 0.2s;
    }
    .hh-carousel{
        position: relative;
        width: 100%;
        height: 100%;
        display: inline;
        overflow: hidden;
        transition: 0.2s;
        /* border: 1px solid red; */
        border-radius: 10px;
    }
    .hhc-smooth{
        scroll-behavior: smooth;
    }
    .hc-part{
        position: relative;
        display: block;
        min-width: 100%;
        width: 100%;
        height: 50%;
        /* border: 1px solid green; */
        padding: 0.5rem;
        transition: 0.2s;
    }
    .hdf-control-button{
        position: absolute;
        z-index: 5;
        background: #ffffff;
        color: grey;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        border: 1px solid whitesmoke;
        border-radius: 37px;
        cursor: pointer;
        transition: 0.2s;
        line-height: 1;
    }
    .hdf-control-button:hover{
        background: whitesmoke;
    }
    .hdf-control-button span{
        /* border: 1px solid red; */
        padding-bottom: 0.2rem;
    }
    .hcb-top-event{
        top: 1.5rem;
        right: 2rem;
        transform: rotate(90deg);
    }
    .hcb-right-event{
        right: 2rem;
        bottom: 1.5rem;
        transform: rotate(90deg);
    }
    .fullscreen-button{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 5;
        cursor: pointer;
        color: rgba(211, 211, 211, 0.534);
        padding: 1rem;
    }
    .hcp-inner{
        position: relative;
        /* display: flex; */
        height: 100%;
        overflow: hidden;
        /* justify-content: center; */
        /* border: 1px solid gold; */
        background: #000000;
        border-radius: 10px;
        /* padding: 0.5rem; */
    }
    .hcp-image{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        /* height: 100%; */
        /* padding: 0.5rem; */
        /* border: 1px solid green; */
        /* border-radius: 10px; */
        overflow: hidden;
    }
    .hcp-image img{
        width: 100%;
        /* max-width: 100%; */
        /* height: 100%; */
        /* max-height: 100%; */
        transition: 0.2s;
        /* border-radius: 10px; */
    }
    /* .img-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    } */
    .hcp-detail{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        /* padding: 0.5rem; */
        background: #0000005d;
    }
    .hcp-detail-inner{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0.5rem;
        padding-right: 5rem;
    }
    .hcp-detail h3{
        margin: 0;
        /* margin-top: 1rem; */
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        font-weight: 700;
        color: #ffffff;
    }
    .hcp-detail p{
        font-size: 1rem;
        color: rgb(255, 255, 255);
    }
    .hdi-text{
        display: block;
    }
    .hcp-detail button{
        border: none;
        border-radius: 50px;
        background: #75C6EF;
        color: #ffffff;
        padding: 0.5rem 2rem 0.5rem 2rem;
        /* padding-left: 2rem;
        padding-right: 2rem; */
        cursor: pointer;
    }
    /* .hcp-description{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.432);
        color: var(--white);
        padding-left: 5%;
        padding-right: 5%;
    } */
    @media only screen and (max-width: 1500px){
        .hdf-image{
            height: 27rem;
        }
    }
    @media only screen and (max-width: 1100px){
        .hc-part{
            height: 100%;
        }
    }
    @media only screen and (max-width: 1050px){
        .hh-left{
            padding-top: 2rem;
            padding-right: 2rem;
        }
        .hh-left h1{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 750px){
        /* .hc-part{
            height: 100%;
        } */
        .hdf-image{
            height: 15rem;
        }
        .hdi-text{
            display: none;
        }
    }
</style>