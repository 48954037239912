<template>
    <CareersComponent/>
</template>

<script setup>
    import CareersComponent from '@/components/careers/CareersComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Kesempatan Karir di RSIA Melinda Bandung',
            description: 'Tenaga kerja yang kompeten, berdedikasi dan bersemangat tinggi untuk kontribusi melayani kesehatan masyarakat merupakan salah satu hal yang kami banggakan.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>






