<template>
    <div class="home-header">
        <div class="hh-carousel">
            <div class="hc-part">
                <div class="hcp-inner">
                    <div class="hh-part hh-left">
                        <div class="hh-left-inner">
                            <h3 class="small-title">Melayani Sepenuh Hati</h3>
                            <h2>You're invited to RSIA Melinda</h2>
                            <p class="p-2">RSIA Melinda merupakan Rumah Sakit Ibu dan Anak. "Kesehatan adalah kunci utama kecantikan dan kebahagiaan seorang wanita. Dengan tulus kami berkomitmen mewujudkan impian setiap wanita untuk tampil cantik, sehat dan bahagia."</p>
                            <div class="complete-description">
                                <div class="cd-button">
                                    <span @click="mainDescription();">Selengkapnya &rarr;</span>
                                </div>
                                <div class="cd-content" id="cd-content">
                                    <div class="cd-content-close" @click="mainDescription();">Tutup</div>
                                    <div class="cd-main-content">
                                        <div class="cmc-content" id="cmc-content">
                                            <h1>RSIA Melinda Bandung</h1>
                                            <p>RSIA Melinda Bandung adalah rumah sakit ibu dan anak yang terletak di Jalan Pajajaran No. 46, Bandung, Jawa Barat. Rumah sakit ini didirikan pada tahun 2004 oleh dr. Susan Melinda, Sp.OG. RSIA Melinda Bandung memiliki visi untuk memberikan pelayanan kesehatan yang profesional dan berkualitas dengan sentuhan kasih kepada ibu dan anak.</p>
                                            <p>RSIA Melinda Bandung memiliki berbagai layanan kesehatan untuk ibu dan anak, antara lain:</p>
                                            <h2>Layanan IGD</h2>
                                            <p>Layanan IGD atau Instalasi Gawat Darurat adalah unit di rumah sakit yang bertanggung jawab untuk memberikan perawatan medis segera bagi pasien yang mengalami kondisi kritis atau darurat medis yang memerlukan penanganan segera. Layanan IGD biasanya tersedia selama 24 jam dan menerima pasien dari berbagai tingkatan keparahan, mulai dari cedera ringan hingga keadaan yang mengancam jiwa.</p>
                                            <h2>Layanan Rawat Jalan (Poliklinik)</h2>
                                            <p>Untuk memperoleh layanan rawat jalan (poliklinik), pasien dapat membuat janji temu dengan dokter lewat pendaftaran yang telah disediakan oleh rumah sakit sesuai dengan waktu praktik dokter tersebut.</p>
                                            <h3>Poliklinik Obstetri dan Ginekologi (Kandungan)</h3>
                                            <p>Tentu tidak asing lagi bagi rumah sakit ibu dan anak jika memiliki poliklinik kandungan, dimana dokter Spesialis Obstetri dan Ginekologi terbaik RSIA Melinda selalu siap melakukan pemeriksaan dan pengobatan bagi ibu hamil terkait dengan kesehatan reproduksi wanita, kehamilan, persalinan, USG atau pasca persalinan. Untuk informasi jadwal dokter kandungan di RSIA Melinda dapat lihat di menu atau menghubungi WA admission.</p>
                                            <h3>Poliklinik Konsultan Fetomaternal</h3>
                                            <p>Poliklinik Fetomaternal merupakan sub spesialisasi dari obstetri dan ginekologi. Dokter Fetomaternal ini berfokus mendeteksi dan mendignosis kelainan pada janin dan ibu. Dokter biasanya akan melakukan pemeriksaan dengan USG Fetomaternal dengan tujuan mencari tahu apakah ibu hamil tersebut memiliki faktor risiko atau kelainan sedini mungkin jika memang terdeteksi. Untuk informasi jadwal dokter Konsultan Fetomaternal di RSIA Melinda dapat lihat di menu atau menghubungi WA admission.</p>
                                            <h3>Poliklinik Anak</h3>
                                            <p>Di poliklinik anak RSIA Melinda, kami memiliki dokter anak yang akan memeriksa kesehatan anak, memberikan diagnosis, dan memberikan perawatan yang tepat untuk penyakit atau kondisi medis yang mungkin dialami oleh anak-anak. Poliklinik anak RSIA Melinda juga menyediakan pemeriksaan rutin dan vaksinasi yang diperlukan untuk menjaga kesehatan anak-anak. Untuk informasi jadwal Dokter Spesialis Anak di RSIA Melinda dapat lihat di menu atau menghubungi WA admission.</p>
                                            <h3>Poliklinik Gizi</h3>
                                            <p>Layanan kesehatan yang berfokus pada pencegahan, diagnosis, dan pengobatan masalah gizi ini memberikan layanan konsultasi untuk pasien dewasa maupun anak-anak. Tujuan utama dari poliklinik gizi adalah untuk membantu pasien memperoleh nutrisi yang cukup, memperbaiki status gizi yang buruk, serta mencegah terjadinya masalah gizi yang lebih serius.</p>
                                            <!-- <h4>Layanan Sub 4</h4> -->
                                            <!-- <h5>Layanan Sub 5</h5> -->
                                            <h3>Poliklinik Kulit dan Kelamin</h3>
                                            <p>Kulit dan kelamin adalah dua bagian tubuh manusia yang memiliki peran dan fungsi yang penting. Poliklinik spesialis kulit dan kelamin bertujuan untuk membantu pasien anak dan dewasa dalam memelihara atau mengobati permasalahan pada kulit. Untuk menjaga kesehatan kulit dan kelamin, penting untuk menjaga kebersihan dan melakukan perawatan yang tepat. Melakukan pemeriksaan rutin pada kedua bagian tubuh ini juga dianjurkan untuk mengetahui kondisi kesehatan secara dini dan mencegah terjadinya masalah kesehatan yang lebih serius di kemudian hari. Untuk informasi jadwal Dokter Spesialis Kulit dan Kelamin di RSIA Melinda dapat lihat di menu atau menghubungi WA admission.</p>
                                            <h3>Poliklinik Bedah Onkologi</h3>
                                            <p></p>
                                            <h2>Layanan Rawat Inap</h2>
                                            <p>Layanan rawat inap mencakup berbagai jenis perawatan, seperti perawatan intensif, perawatan pasca persalinan, perawatan pasca operasi, perawatan anak. Pasien akan dirawat di kamar yang dilengkapi dengan berbagai fasilitas seperti tempat tidur, toilet, shower dan televisi. Selain itu, pasien juga akan diberikan makanan dan minuman yang sesuai dengan kondisi kesehatannya.</p>
                                            <h3>Layanan Persalinan</h3>
                                            <p>Persalinan adalah layanan penting bagi RSIA Melinda dalam membantu ibu melakukan proses persalinan. Proses persalinan di RSIA Melinda</p>
                                            <!-- <h4>Layanan Sub 4</h4> -->
                                            <!-- <h5>Layanan Sub 5</h5> -->
                                            <!-- <h6>Layanan Sub 6</h6> -->
                                        </div>
                                        <div class="cmc-index-button" @click="cmcIndex();">
                                            <span>Daftar Isi</span>
                                        </div>
                                        <div class="cmc-index-shadow" id="cmc-index-shadow" @click="cmcIndex();"></div>
                                        <div class="cmc-index" id="cmc-index"></div>
                                    </div>
                                    <!-- <div class="cd-content-inner">
                                    </div> -->
                                </div>
                            </div>
                            <div class="more button-main">
                                <router-link to="/services">Lihat Semua Services</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="hh-part hh-right">
                        <img fetchpriority="high" src="../../assets/images/pictures/admission.webp" alt="selamat datang di rsia melinda" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { mainDescription, cmcIndex } from '@/functions/home';
    import { articleIndex, createHtmlIndex } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        const indexResult = articleIndex(document.getElementById('cmc-content'));
        const htmlIndex = createHtmlIndex(indexResult);
        const cmcindex = document.getElementById('cmc-index');
        cmcindex.innerHTML = '';
        cmcindex.appendChild(htmlIndex);
        // document.getElementById('cmc-index').innerHTML = htmlIndex;
        // console.log(indexResult);
        // console.log(htmlIndex);
    });
    // function cmcIndex(){
    //     const index = document.getElementById('cmc-index');
    //     const shadow = document.getElementById('cmc-index-shadow');
    //     if(index.classList.contains('cmc-index-active')){
    //         index.classList.remove('cmc-index-active');
    //         shadow.classList.remove('cis-active');
    //     }else{
    //         index.classList.add('cmc-index-active');
    //         shadow.classList.add('cis-active');
    //     }
    // }
</script>

<style scoped>
    @import url('../../assets/css/articleIndex.css');
    .home-header{
        position: relative;
        width: 100%;
        display: flex;
        /* overflow: hidden; */
        transition: 0.2s;
        background: #1B4674;
        z-index: 11;
        /* border: 1px solid green; */
    }
    .hh-carousel{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        transition: 0.2s;
    }
    .hc-part{
        display: inline-block;
        min-width: 100%;
        width: 100%;
    }
    .hcp-inner{
        position: relative;
        display: flex;
        overflow: hidden;
        transition: 0.2s;
    }
    .hh-part{
        width: 50%;
    }
    .hh-left{
        color: #ffffff;
        padding: 8.44rem;
        padding-left: 12.25rem;
        padding-right: 6.69rem;
        padding-bottom: 2.5rem;
        transition: 0.2s;
        z-index: 1;
        /* border: 1px solid green; */
    }
    .small-title{
        font-weight: 600;
        font-size: 1.1rem;
        font-family: poppins;
        color: #75C6EF;
    }
    .hh-left h2{
        font-family: poppins;
        font-size: 2rem;
        margin-top: 1.37rem;
        transition: 0.2s;
    }
    .p-2{
        margin: 1.19rem 0 0.5rem 0;
    }
    .cd-button span{
        cursor: pointer;
        color: var(--primary);
        font-style: italic;
        font-size: 0.9rem;
    }
    .cd-content{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* overflow: auto; */
        background: #00000050;
        /* transition: 0.5s; */
        /* z-index: 10000000000; */
        display: none;
        padding: 2.5rem 1rem 2.5rem 1rem;
    }
    .cd-content-active{
        /* position: relative; */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* padding: 1rem; */
        /* overflow: auto; */
        display: block;
    }
    .cd-main-content{
        position: absolute;
        top: 2.5rem;
        right: 10%;
        bottom: 2.5rem;
        left: 10%;
        display: flex;
        color: var(--grey);
        background: var(--white);
        padding: 1rem;
        border-radius: 10px;
        -webkit-animation-name: animatemaindesc;
		-webkit-animation-duration: 0.5s;
		animation-name: animatemaindesc;
		animation-duration: 0.5s;
        overflow: auto;
        transition: 0.2s;
    }
    .cmc-content p{
        font-family: poppins-regular;
    }
    /* .cmc-content h2, h3, h4, h5, h6{
        font-family: poppins-regular;
    } */
    @-webkit-keyframes animatemaindesc {
		from {top:200%; opacity:0}
		to {top:2.5rem; opacity:1}
	}
	@keyframes animatemaindesc {
		from {top:200%; opacity:0}
		to {top:2.5rem; opacity:1}
	}
    .cd-content-close{
        position: absolute;
        top: 0;
        left: 0;
        width: 37px;
        height: 37px;
        padding: 1rem;
        color: var(--white);
        cursor: pointer;
        z-index: 1;
        /* border: 1px solid green; */
    }
    .cmc-content{
        width: 70%;
        max-height: 100%;
        overflow: auto;
        padding: 0 0.5rem 0 0;
        scroll-behavior: smooth;
    }
    .cmc-index-button{
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem 2rem 0 0;
        background: var(--white);
        color: var(--primary);
        font-weight: 500;
        /* border-bottom: 1px solid var(--softgrey); */
        /* border-left: 1px solid var(--softgrey); */
        /* border-bottom-left-radius: 10px; */
        display: none;
        opacity: 0.9;
    }
    .cmc-index-shadow{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 150%;
        background: #00000050;
        display: none;
        z-index: 1;
    }
    .cmc-index{
        width: 30%;
        max-height: 100%;
        overflow: auto;
        padding: 0 0 0 0.5rem;
        background: var(--white);
        z-index: 5;
        transition: 0.2s;
    }
    .more{
        margin-top: 2rem;
    }
    .hh-right{
        position: relative;
        display: flex;
        flex-direction: column;
        transition: 0.2s;
        /* justify-content: center; */
        align-items: center;
    }
    .hh-right img{
        width: 100%;
        display: block;
        transition: 0.2s;
        z-index: 0;
    }
    @media only screen and (max-width: 1500px){
        .hh-left{
            padding-top: 2.6rem;
            padding-right: 2.6rem;
            padding-left: 5%;
        }
        .cd-main-content{
            right: 5%;
            left: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .hh-left{
            padding-top: 2rem;
            padding-right: 2rem;
        }
        .hh-left h1{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 750px){
        .home-header{
            /* height: 700px; */
        }
        .hcp-inner{
            height: 700px;
            /* border: 1px solid yellow; */
        }
        .hh-left{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #1b467454;
            padding-top: 17rem;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .small-title{
            color: var(--white);
        }
        .hh-left h2{
            font-size: 2rem;
            width: auto;
            /* margin: 12rem 0 0 0; */
        }
        .cd-button span{
            color: var(--white);
        }
        .cd-main-content{
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 0;
            overflow: hidden;
            -webkit-animation-name: unset;
            animation-name: unset;
            padding: 2.5rem 1rem 1rem 1rem;
        }
        .cd-content-close{
            color: var(--primary);
        }
        .cmc-content{
            width: 100%;
        }
        .cmc-index-button{
            display: block;
        }
        .cis-active{
            display: block;
            right: 0;
        }
        .cmc-index{
            position: absolute;
            top: 0;
            right: -100%;
            width: 75%;
            height: 100%;
            border-left: 1px solid var(--softgrey);
            padding: 1rem 0 0 0.5rem;
        }
        .cmc-index-active{
            right: 0;
        }
        .hh-right{
            width: 100%;
        }
        .hh-right img{
            width: auto;
            height: 100%;
        }
    }
    @media only screen and (max-width: 500px){
        .hh-left{
            padding-top: 20rem;
        }
        .hcp-inner{
            height: 850px;
        }
        .hh-left h1{
            font-size: 1.5rem;
            width: auto;
        }
        .hh-right img{
            width: unset;
        }
    }
    @media only screen and (max-width: 350px){
        /* .hh-left{
            padding-top: 1rem;
        } */
    }
</style>