import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ServicesView from '../views/ServicesView.vue';
import ServiceDetailView from '../views/ServiceDetailView.vue';
// import ArticlesView from '../views/ArticlesView.vue';
// import ArticleCategoryView from '../views/ArticleCategoryView.vue';
import ArticleDetailView from '../views/ArticleDetailView.vue';
import RoomView from '../views/RoomView.vue';
import RoomDetailView from '../views/RoomDetailView.vue';
import DoctorsView from '../views/DoctorsView.vue';
import AboutView from '../views/AboutView.vue';
import EventView from '../views/EventView.vue';
import EventDetailView from '../views/EventDetailView.vue';
import EventFormView from '../views/EventFormView.vue';
import EventAttendanceFormView from '../views/EventAttendanceFormView.vue';
import SurveyFormView from '../views/SurveyFormView.vue';
import PromoView from '../views/PromoView.vue';
import PromoDetailView from '../views/PromoDetailView.vue';
import PromoFormView from '../views/PromoFormView.vue';
import LinkView from '../views/LinkView.vue';
import CareersView from '../views/CareersView.vue';
import CareerDetailView from '../views/CareerDetailView.vue';
import GalleryView from '../views/GalleryView.vue';
import GalleryDetailView from '../views/GalleryDetailView.vue';
import ContactView from '../views/ContactView.vue';
import ShortenerView from '../views/ShortenerView.vue';
import NotFoundView from '../views/NotFoundView.vue';
import SiteRedirectView from '../views/SiteRedirectView.vue';

// import { useShortenerStore } from '@/stores/shortener';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/services/detail/:slug',
    name: 'serviceDetail',
    component: ServiceDetailView
  },
  // {
  //   path: '/articles',
  //   name: 'articles',
  //   component: ArticlesView
  // },
  {
    path: '/articles/detail/:slug',
    name: 'articleDetail',
    component: ArticleDetailView
  },
  // {
  //   path: '/articles/category/:slug',
  //   name: 'articleCategory',
  //   component: ArticleCategoryView
  // },
  {
    path: '/rooms',
    name: 'rooms',
    component: RoomView
  },
  {
    path: '/rooms/detail/:slug',
    name: 'roomDetail',
    component: RoomDetailView
  },
  {
    path: '/doctors',
    name: 'doctors',
    component: DoctorsView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  // {
  //   path: '/articles',
  //   name: 'articles',
  //   component: ArticlesView
  // },
  // {
  //   path: '/articles/detail/:slug',
  //   name: 'articleDetail',
  //   component: ArticleDetailView
  // },
  {
    path: '/event',
    name: 'event',
    component: EventView
  },
  {
    path: '/event/detail/:slug',
    name: 'eventDetail',
    component: EventDetailView
  },
  {
    path: '/event/form/:slug',
    name: 'eventForm',
    component: EventFormView
  },
  {
    path: '/attendance/form/:slug',
    name: 'eventAttendance',
    component: EventAttendanceFormView
  },
  {
    path: '/survey/form/:slug',
    name: 'surveyForm',
    component: SurveyFormView
  },
  {
    path: '/paket',
    name: 'paket',
    component: PromoView
  },
  {
    path: '/paket/detail/:slug',
    name: 'promoDetail',
    component: PromoDetailView
  },
  {
    path: '/paket/form/:slug',
    name: 'promoForm',
    component: PromoFormView
  },
  {
    path: '/careers',
    name: 'careers',
    component: CareersView
  },
  {
    path: '/careers/detail/:slug',
    name: 'careerDetail',
    component: CareerDetailView
  },
  // {
  //   path: '/auction',
  //   name: 'auction',
  //   component: ArticlesView
  // },
  {
    path: '/link',
    name: 'link',
    component: LinkView
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/gallery/detail/:slug',
    name: 'galleryDetail',
    component: GalleryDetailView
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/site-redirect',
    name: 'siteRedirect',
    component: SiteRedirectView
  },
  {
    path: '/s/:token',
    name: 'shortener',
    component: ShortenerView
    // {
    //   render: function(){
    //     const token = this.$route.params.token;
    //     useShortenerStore().getUrl(token);
    //   }
    // }
  },
  {
    path: '/business-card/susan-melinda',
    name: 'bCardDrSusan',
    // meta: {
    //   staticPage: true
    // },
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/dr-susan-melinda.html'
    }
  },
  {
    path: '/business-card/dr-grace',
    name: 'bCardDrGrace',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/dr-grace.html'
    }
  },
  // {
  //   path: '/business-card/efni-elita',
  //   name: 'bCardEfniElita',
  //   beforeEnter(){
  //     location.href = process.env.BASE_URL + 'business-card/efni-elita.html'
  //   }
  // },
  {
    path: '/business-card/sanny',
    name: 'bCardSanny',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/sanny.html'
    }
  },
  // {
  //   path: '/business-card/dr-tammy-siarif',
  //   name: 'bCardDrTammy',
  //   beforeEnter(){
  //     location.href = process.env.BASE_URL + 'business-card/dr-tammy-siarif.html'
  //   }
  // },
  {
    path: '/business-card/cardiac-rehabilitation',
    name: 'cardiacRehabilitation',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/cardiac-rehabilitation.html'
    }
  },
  {
    path: '/business-card/dr-ellyana',
    name: 'drEllyana',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/dr-ellyana.html'
    }
  },
  {
    path: '/business-card/melinda-cvc',
    name: 'melindCvc',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/melinda-cvc.html'
    }
  },
  {
    path: '/not-found',
    name: 'notFound',
    component: NotFoundView,
    // beforeEnter(){
    //   location.href = process.env.BASE_URL + 'notfound.html'
    // }
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFoundHard',
    // redirect: '/not-found',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'not-found';
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// router.beforeEach((to, from, next) => {
//   // const loggedIn = useAccountStore().loggedIn;
//   if (to.matched.some(record => record.meta.staticPage)) {
//     console.log('static page');
//       // if (!getCookie('session')) {
//       //   next({ name: 'home' });
//       // } else {
//       //   next();
//       // }
//   } else {
//     next();
//     // if (to.matched.some(record => record.meta.hideForAuth)) {
//     //     if (getCookie('session')) {
//     //       // console.log('hide');
//     //       next({ name: 'home' });
//     //     } else {
//     //       next();
//     //     }
//     // } else {
//     // }
//     // next();
//   }
// });

export default router


