<template>
    <section>
        <div class="section-inner" v-if="recommendation">
            <div class="section-head">
                <div class="sh-title">
                    <h2>
                        <div>{{ recommendation.head.title.replace('dasi Ka', 'dasi#Ka').split('#')[0] }}</div>
                        <div>{{ recommendation.head.title.replace('dasi Ka', 'dasi#Ka').split('#')[1] }}</div>
                    </h2>
                </div>
                <div class="sh-link">
                    <router-link to="/rooms">
                        <span>Browse Others Room</span>
                    </router-link>
                </div>
            </div>
            <div class="section-body">
                <div class="sb-left">
                    <router-link to="/rooms/detail/baby-room">
                        <div class="sb-left-image">
                            <img :src="recommendation.body.kid.image" alt="rsia melinda"/>
                        </div>
                        <div class="sb-left-text">
                            <h3>{{ recommendation.body.kid.title }}</h3>
                        </div>
                    </router-link>
                </div>
                <div class="sb-right">
                    <router-link to="/rooms/detail/suite-room-balcony">
                        <div class="sb-right-image">
                            <img :src="recommendation.body.family.image" alt="rsia melinda"/>
                        </div>
                        <div class="sb-right-text">
                            <h3>{{ recommendation.body.family.title }}</h3>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useHomeStore } from '@/stores/home';
    const recommendation = computed(() => useHomeStore().recommendation);
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 0 10%;
        transition: 0.2s;
    }
    .section-inner{
        position: relative;
    }
    .section-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        /* height: 75px; */
        display: flex;
        transition: 0.2s;
        z-index: 1;
        /* border: 1px solid green; */
    }
    .sh-title{
        width: 70%;
    }
    .sh-title h2{
        margin: 0;
        font-size: 2rem;
        font-family: poppins;
        color: var(--navy);
        line-height: 1;
        /* white-space: wrap; */
    }
    .sh-link{
        width: 30%;
        display: flex;
        flex-direction: row-reverse;
        align-items: end;
        /* text-align: right; */
        padding: 1rem 0 0 0;
        /* border: 1px solid green; */
    }
    .sh-link a{
        color: var(--navy);
        text-decoration: underline;
    }
    .section-body{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 600px;
        overflow: hidden;
        transition: 0.2s;
        /* border: 1px solid green; */
    }
    .sb-left{
        position: relative;
        width: 40%;
        padding: 0 1rem 0 0;
        transition: 0.2s;
    }
    .sb-left-image img{
        width: 100%;
    }
    .sb-left-text{
        position: absolute;
        top: 500px;
        left: 0;
        width: 100%;
        padding: 1rem;
        transition: 0.2s;
    }
    .sb-left-text h3{
        font-size: 1.5rem;
        color: var(--white);
        margin: 0;
        transition: 0.2s;
    }
    .sb-right{
        position: relative;
        width: 60%;
        height: 100%;
        padding: 100px 0 0 0;
        transition: 0.2s;
    }
    .sb-right-text{
        position: absolute;
        top: 500px;
        left: 0;
        width: 100%;
        padding: 1rem;
        transition: 0.2s;
    }
    .sb-right-text h3{
        font-size: 1.5rem;
        color: var(--white);
        margin: 0;
    }
    .sb-right-image{
        height: 100%;
    }
    .sb-right-image img{
        height: 100%;
        transition: 0.2s;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 0 5%;
        }
    }
    @media only screen and (max-width: 1200px){
        .section-head{
            position: relative;
            height: auto;
            width: 100%;
            padding: 0 0 1rem 0;
        }
        .section-body{
            height: auto;
        }
        .sb-left{
            width: 100%;
            padding: 0;
        }
        .sb-left-text{
            top: unset;
            bottom: 0;
        }
        .sb-left-text h3{
            color: var(--navy);
        }
        .sb-right{
            width: 100%;
            padding: 1rem 0 0 0;
        }
        .sb-right-text{
            top: unset;
            bottom: 0;
        }
        .sb-right-image img{
            width: 100%;
            height: unset;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 0 2.5%;
        }
    }
</style>