<template>
    <div class="cov-section" v-if="servicesShow && layouts">
        <section :class="layouts[index].style.color" v-for="(service, index) in servicesShow" :key="service">
            <div :class="'section-bg ' + layouts[index].style.background"></div>
            <div :class="'section-inner ' + layouts[index].sectionClasses">
                <div class="si-picture">
                    <div class="si-picture-img">
                        <img :src="service.img_url" alt="rs melinda 3"/>
                    </div>
                </div>
                <div :class="'si-text ' + layouts[index].linkColor">
                    <div class="cov-strip">
                        <div :class="'strip strip-' + layouts[index].strip"></div>
                    </div>
                    <router-link :to="'/services/detail/' + service.slug">
                        <h2>{{ service.name }}</h2>
                    </router-link>
                    <p>{{ wordsLimit(removeHTML(service.description), 370) }}</p>
                    <div class="si-text-button">
                        <router-link :to="'/services/detail/' + service.slug">
                            <span>Lihat Detail</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <div class="section-more" v-if="servicesShow.length < services.length">
            <button @click="useServiceStore().moreServices();">Load more services</button>
        </div>
    </div>
    <ServicesSkeleton v-else/>
</template>

<script setup>
    import ServicesSkeleton from '@/components/skeletons/sections/ServicesSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useServiceStore } from '@/stores/service';
    import { removeHTML, wordsLimit } from '@/functions/general';
    const services = computed(() => useServiceStore().services);
    const servicesShow = computed(() => useServiceStore().servicesShow);
    const layouts = computed(() => useServiceStore().layouts);
    useServiceStore().setServices();
</script>

<style scoped>
    .cov-section{
        position: relative;
    }
    section{
        position: relative;
        padding: 2.5rem 10% 2.5rem 10%;
        overflow: hidden;
        transition: 0.2s;
    }
    .section-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .section-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .section-row{
        flex-direction: row;
    }
    .section-row-reverse{
        flex-direction: row-reverse;
    }
    .si-picture{
        width: 50%;
        transition: 0.2s;
    }
    .section-row-reverse .si-picture{
        padding: 0 0 0 2rem;
    }
    .si-picture-img{
        width: 100%;
    }
    .si-picture-img img{
        width: 100%;
    }
    .si-text{
        width: 50%;
        padding: 0 0 0 2rem;
        transition: 0.2s;
        text-align: justify;
    }
    .section-row-reverse .si-text{
        padding: 0 1rem 0 0;
    }
    .si-text a{
        text-decoration: none;
    }
    .si-text h2{
        font-family: poppins;
        text-align: left;
    }
    .cov-strip{
        display: flex;
        justify-content: left;
        transition: 0.2s;
    }
    .strip{
        width: 5%;
        height: 0.5rem;
        /* background: var(--navy); */
        transition: 0.2s;
    }
    .strip-navy{
        background: var(--navy);
    }
    .strip-white{
        background: var(--white);
    }
    .si-text-button{
        padding: 1rem 0 1rem 0;
    }
    .si-text-button a{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 5rem;
    }
    .section-more{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1rem 0 1rem 0;
    }
    .section-more button{
        font-family: poppins;
        font-weight: 500;
        font-size: 1rem;
        border: none;
        background: none;
        color: var(--grey);
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .si-picture{
            width: 100%;
        }
        .section-row-reverse .si-picture{
            padding: 0;
        }
        .si-text{
            width: 100%;
            padding: 2.5rem 1rem 0 0;
        }
        .section-row-reverse .si-text{
            padding: 2.5rem 1rem 0 0;
        }
    }
</style>