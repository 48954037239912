import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { servicesLayout } from "@/data/layouts.js";
import { createSlug, setPageData, wordsLimit, removeHTML } from "@/functions/general.js";

export const useServiceStore = defineStore("service", {
    state: () => ({
        // ==================== states used on services page and its relation
        allServices: null,
        services: null,
        searchBar: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        categories: null,
        servicesShow: null,
        layouts: null,
        serviceDetail: null,
        serviceHeader: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on services page and its relation
        async setServices(){
            try{
                this.layouts = servicesLayout;
                const response = await axiosClient.get(`service`);
                const header = await axiosClient.get(`page-header/service`);
                this.allServices = response.data.services;
                this.categories = response.data.categories;
                this.serviceHeader = header.data.page_header;
                for(var i = 0; i < this.allServices.length; i++){
                    this.allServices[i].slug = createSlug(this.allServices[i].name);
                }
                this.services = this.allServices;
                this.showServices(6);
            }catch(error){
                console.log(error);
            }
        },
        async setServiceDetail(slug){
            if(!this.allServices){
                await this.setServices();
            }
            this.serviceDetail = null;
            var serviceId;
            for(var i = 0; i < this.allServices.length; i++){
                if(this.allServices[i].slug == slug){
                    serviceId = this.allServices[i].service_id;
                }
            }
            try{
                if(serviceId){
                    const response = await axiosClient.get(`service/` + serviceId);
                    // console.log(response);
                    const footer = await axiosClient.get(`footer_service`);
                    this.serviceDetail = response.data.service;
                    this.serviceDetail.footer = footer.data.data;
                    // console.log(this.serviceDetail);
                    setPageData({
                        title: this.serviceDetail.name,
                        description: wordsLimit(removeHTML(this.serviceDetail.description), 200)
                    });
                }else{
                    location.href = process.env.BASE_URL + 'not-found';
                }
            }catch(error){
                console.log(error);
            }
        },
        showServices(limit){
            this.servicesShow = this.services.slice(0, limit);
        },
        moreServices(){
            const ln = this.servicesShow.length;
            this.showServices((ln + 6));
        },
        searchbarSelect(category, slug){
            this.searchBar.select.title = category;
            this.searchBar.select.value = slug;
            this.searchService();
        },
        searchbarInput(value){
            this.searchBar.input.value = value;
            this.searchService();
        },
        searchService(){
            var newsd = [];
            if(this.searchBar.select.value != 'all'){ // if selected specialist
                for(var i = 0; i < this.allServices.length; i++){
                    if(this.allServices[i].category.name == this.searchBar.select.title){
                        newsd.push(this.allServices[i]);
                    }
                }
            }else{
                newsd = this.allServices;
            }
            if(this.searchBar.input.value){
                var result = [];
                var inval = new RegExp(this.searchBar.input.value, 'gi');
                for(i = 0; i < newsd.length; i++){
                    if(newsd[i].name.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.services = result;
            }else{
                this.services = newsd;
            }
            this.showServices(6);
        }
    }
});