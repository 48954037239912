import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useAboutStore = defineStore("about", {
    state: () => ({
        // ==================== states used on about page and its relation
        head: null,
        body: null,
        disclaimer: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on about page and its relation
        async setAbout(){
            if(!this.head){
                this.setHead();
            }
            if(!this.body){
                this.setBody();
            }
        },
        async setHead(){
            try{
                const response = await axiosClient.get(`page-header/about`);
                this.head = response.data.page_header;
                // console.log(this.head);
            }catch(error){
                console.log(error);
            }
        },
        async setBody(){
            try{
                const response = await axiosClient.get(`about`);
                this.body = response.data;
                // console.log(this.body);
            }catch(error){
                console.log(error);
            }
        },
        async setDisclaimer(){
            try{
                const response = await axiosClient.get(`disclaimer`);
                this.disclaimer = response.data.data;
                // console.log(this.disclaimer);
            }catch(error){
                console.log(error);
            }
        }
    }
});