<template>
    <PromoComponent/>
</template>

<script setup>
    import PromoComponent from '@/components/promo/PromoComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>