import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { createSlug } from "@/functions/general.js";

export const useCareerStore = defineStore("career", {
    state: () => ({
        // ==================== states used on careers page and its relation
        allVacancies: null,
        vacancies: null, // vacancies unlimited
        vacanciesShow: null, // vacancies to show
        limit: 12,
        departments: null,
        searchBar: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        vacancyDetail: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on careers page and its relation
        async setVacancies(){
            try{
                const response = await axiosClient.get(`career`);
                this.allVacancies = response.data.jobs;
                for(var i = 0; i < this.allVacancies.length; i++){
                    this.allVacancies[i].slug = createSlug(this.allVacancies[i].name);
                }
                // this.departments = resdept.data.data;
                this.showVacancies(this.allVacancies, this.limit);
                // console.log(this.allVacancies);
            }catch(error){
                console.log(error);
            }
        },
        async setVacancyDetail(slug){
            this.vacancyDetail = null;
            if(!this.allVacancies){
                await this.setVacancies();
            }
            var vacancy = null;
            for(var i = 0; i < this.allVacancies.length; i++){
                if(this.allVacancies[i].slug == slug){
                    vacancy = this.allVacancies[i];
                }
            }
            const response = await axiosClient.get(`career/` + vacancy.job_vacancy_id);
            this.vacancyDetail = response.data.job;
            // console.log(response);
        },
        showVacancies(data, limit){
            var dataShow = [];
            for(var i = 0; i < data.length; i++){
                dataShow.push(data[i]);
                // for(var j = 0; j < data[i].vacancies.length; j++){
                // }
            }
            this.vacancies = dataShow;
            this.vacanciesShow = dataShow.slice(0, limit);
            // console.log(this.vacanciesShow);
        },
        moreVacancies(){
            const ln = this.vacanciesShow.length;
            this.vacanciesShow = this.vacancies.slice(0, (ln + this.limit));
        },
        searchbarSelect(department, slug){
            this.searchBar.select.title = department;
            this.searchBar.select.value = slug;
            this.searchVacancy();
        },
        searchbarInput(value){
            this.searchBar.input.value = value;
            this.searchVacancy();
        },
        searchVacancy(){
            var newsd = [];
            if(this.searchBar.select.value != 'all'){ // if selected department
                for(var i = 0; i < this.allVacancies.length; i++){
                    if(this.allVacancies[i].slug == this.searchBar.select.value){
                        newsd.push(this.allVacancies[i]);
                    }
                }
            }else{
                newsd = this.allVacancies;
            }
            if(this.searchBar.input.value){
                var result = [];
                var inval = new RegExp(this.searchBar.input.value, 'gi');
                for(i = 0; i < newsd.length; i++){
                    var thisvacans = [];
                    for(var j = 0; j < newsd[i].vacancies.length; j++){
                        if(newsd[i].vacancies[j].name.match(inval)){
                            thisvacans.push(newsd[i].vacancies[j]);
                        }
                    }
                    if(thisvacans.length > 0){
                        result.push({name: newsd[i].name, slug: newsd[i].slug, vacancies: thisvacans});
                    }
                }
                this.showVacancies(result, this.limit);
            }else{
                this.showVacancies(newsd, this.limit);
            }
        }
    }
});