<template>
    <div v-if="promoList">
        <div v-if="promoList.length > 0">
            <div class="hdf-image" id="home-promo-carousel">
                <div class="hdf-control">
                    <div class="hdf-control-button hcb-left-promo">
                        <span>{{ '<' }}</span>
                    </div>
                    <div class="hdf-control-button hcb-right-promo">
                        <span>{{ '>' }}</span>
                    </div>
                </div>
                <div class="hh-carousel promo-carousel hhc-smooth" v-if="promoList">
                    <div class="hc-part" v-for="promo in promoList" :key="promo">
                        <div class="hcp-inner">
                            <div class="hcp-image">
                                <div class="img-cover"></div>
                                <img :src="'https://backend1.melindadev.com/' + promo.image" alt="melinda care">
                            </div>
                            <div class="hcp-detail">
                                <h3>{{ promo.name }}</h3>
                                <p>
                                    {{ wordsLimit(removeHTML(promo.description), 91) }}
                                    <router-link :to="'/promo/detail/' + promo.slug">
                                        <button>Selengkapnya</button>
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <p style="font-style: italic; text-align: center;">Saat ini sedang tidak ada promo</p>
        </div>
    </div>
    <div v-else>
        <div class="hh-carousel promo-carousel hhc-smooth">
            <div class="hc-part" v-for="n in 11" :key="n">
                <div class="hcp-inner">
                    <div class="hcp-image">
                        <div class="img-cover"></div>
                        <!-- <img :src="'https://backend1.melindadev.com/' + promo.image" alt="melinda care"> -->
                    </div>
                    <div class="hcp-detail">
                        <h3>Loading.....</h3>
                        <p>
                            {{ wordsLimit(removeHTML('Loading'), 91) }}
                            <router-link to="">
                                <button>Selengkapnya</button>
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { onMounted } from 'vue';
    import { wordsLimit, removeHTML } from '@/functions/general';
    import { usePromoStore } from '@/stores/promo';
    const promoList = computed(() => usePromoStore().promoList);
    usePromoStore().setPromoList();
    onMounted(() => {
        setTimeout(function(){
            if(document.getElementById('home-promo-carousel')){
                // const date = new Date();
                let isDown = false;
                let mouseOver = false;
                let startX;
                let scrollLeft;
                // let action = false;
                const slider = document.querySelector('.promo-carousel');
                const btleft = document.querySelector('.hcb-left-promo');
                const btright = document.querySelector('.hcb-right-promo');
                // const schildren = slider.children;
                // const sparent = slider.parentElement;
                const end = () => {
                    isDown = false;
                    slider.classList.add('hhc-smooth');
                    slider.scrollLeft = Math.round(slider.scrollLeft / slider.children[0].scrollWidth) * slider.children[0].scrollWidth;
                }
                const forceEnd = () => {
                    isDown = false;
                    const scrollmea = Math.round(slider.scrollLeft / slider.children[0].scrollWidth) * slider.children[0].scrollWidth;
                    // slider.classList.add('hhc-smooth');
                    // slider.scrollLeft = Math.round(slider.scrollLeft / slider.children[0].scrollWidth) * slider.children[0].scrollWidth;
                    if(scrollLeft != scrollmea){
                        if(scrollLeft > scrollmea){
                            moveLeft();
                            slider.classList.remove('hhc-smooth');
                            slider.scrollLeft = slider.scrollLeft + slider.children[0].scrollWidth;
                            slider.classList.add('hhc-smooth');
                            // console.log('Right');
                        }else{
                            moveRight();
                            slider.classList.remove('hhc-smooth');
                            slider.scrollLeft = slider.scrollLeft - slider.children[0].scrollWidth;
                            slider.classList.add('hhc-smooth');
                            // console.log('Left');
                        }
                    }
                    end();
                }
                const over = () => {
                    mouseOver = true;
                }
                const leave = () => {
                    mouseOver = false;
                }
                const start = (e) => {
                    isDown = true;
                    slider.classList.remove('hhc-smooth');
                    startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                }
                const scroll = () => {
                    // const el = slider.children[0];
                    // slider.appendChild(el);
                    // console.log(slider.scrollLeft);
                }
                const move = (e) => {
                    if(!isDown) return;
                    // e.preventDefault();
                    const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
                    const dist = (x - startX) * 1.9;
                    slider.scrollLeft = scrollLeft - dist;
                    if((slider.children[0].scrollWidth - dist) > (slider.children[0].scrollWidth * 1.6) || (slider.children[0].scrollWidth - dist) < (slider.children[0].scrollWidth / 2)){
                        forceEnd();
                    }
                    // console.log('dist : ' + (slider.children[0].scrollWidth - dist));
                    // console.log('vs : ' + (slider.children[0].scrollWidth * 1.6) + ' || ' + (slider.children[0].scrollWidth / 2));
                }
                const goLeft = (e) => {
                    e.preventDefault();
                    slider.scrollLeft = (Math.round(slider.scrollLeft / slider.children[0].scrollWidth) * slider.children[0].scrollWidth - slider.children[0].scrollWidth);
                    setTimeout(function(){
                        // if(slider.scrollLeft > (slider.children[0].scrollWidth)){
                            moveLeft();
                            slider.classList.remove('hhc-smooth');
                            slider.scrollLeft = slider.scrollLeft + slider.children[0].scrollWidth;
                            slider.classList.add('hhc-smooth');
                        // }
                    }, 500);
                    // const el = slider.children[0];
                    // slider.appendChild(el);
                    // goTop();
                    // setTimeout(function(){
                    //     console.log(slider.scrollLeft);
                    // }, 500);
                    // console.log(el);
                }
                const goRight = (e) => {
                    e.preventDefault();
                    slider.scrollLeft = (Math.round(slider.scrollLeft / slider.children[0].scrollWidth) * slider.children[0].scrollWidth + slider.children[0].scrollWidth);
                    // console.log((Math.round(slider.scrollLeft / slider.children[0].scrollWidth) * slider.children[0].scrollWidth + slider.children[0].scrollWidth));
                    setTimeout(function(){
                        // if(slider.scrollLeft > (slider.children[0].scrollWidth)){
                            moveRight();
                            slider.classList.remove('hhc-smooth');
                            slider.scrollLeft = slider.scrollLeft - slider.children[0].scrollWidth;
                            slider.classList.add('hhc-smooth');
                        // }
                    }, 500);
                }
                const moveLeft = () => {
                    const el = slider.children[(slider.children.length - 1)];
                    slider.children[(slider.children.length - 1)].remove();
                    slider.prepend(el);
                }
                const moveRight = () => {
                    const el = slider.children[0];
                    slider.children[0].remove();
                    slider.appendChild(el);
                }
                const sresize = () => {
                    slider.scrollLeft = 0;
                }
                // const goTop = () => {
                //     document.body.scrollTop = 0;
                //     document.documentElement.scrollTop = 0;
                // }
                (() => {
                    slider.addEventListener('mousedown', start);
                    slider.parentElement.addEventListener('mouseover', over);
                    slider.parentElement.addEventListener('mouseleave', leave);
                    slider.addEventListener('touchstart', start);
                    slider.addEventListener('scroll', scroll);
                    slider.addEventListener('mousemove', move);
                    slider.addEventListener('touchmove', move);
                    slider.addEventListener('mouseleave', end);
                    slider.addEventListener('mouseup', end);
                    slider.addEventListener('touchend', end);
                    slider.addEventListener('resize', sresize);
                    btleft.addEventListener('click', goLeft);
                    btright.addEventListener('click', goRight);
                    for(var i = 0; i < slider.children.length; i++){
                        const el = slider.children[i];
                        slider.appendChild(el);
                    }
                    slider.scrollLeft = slider.children[0].scrollWidth * (Math.round(slider.children.length / 2));
                    setInterval(function(){
                        if(!mouseOver){
                            // btright.click();
                        }
                    }, 5000);
                    // console.log(slider.children[0].scrollWidth * (Math.round(slider.children.length / 2)));
                })();
            }
        }, 5000);
    });
    // hello();
</script>

<style scoped>
    .hdf-image{
        position: relative;
        width: 100%;
        display: flex;
        height: 37rem;
        overflow: hidden;
        user-select: none;
        /* background: #ffffff; */
        transition: 0.2s;
    }
    .hh-carousel{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        transition: 0.2s;
    }
    .hhc-smooth{
        scroll-behavior: smooth;
    }
    .hc-part{
        position: relative;
        display: inline-block;
        min-width: 33.33%;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .hdf-control-button{
        position: absolute;
        z-index: 5;
        background: #ffffff;
        color: grey;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        border: 1px solid whitesmoke;
        border-radius: 37px;
        cursor: pointer;
        transition: 0.2s;
        line-height: 1;
    }
    .hdf-control-button:hover{
        background: whitesmoke;
    }
    .hcb-left-promo{
        top: 20%;
        left: 1.5rem;
    }
    .hcb-right-promo{
        top: 20%;
        right: 1.5rem;
    }
    .fullscreen-button{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 5;
        cursor: pointer;
        color: rgba(211, 211, 211, 0.534);
        padding: 1rem;
    }
    .hcp-inner{
        position: relative;
        /* display: flex; */
        height: 100%;
        overflow: hidden;
        /* justify-content: center; */
        background: #ffffff;
        /* border: 1px solid lightgrey; */
        border-radius: 10px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.12);
    }
    .hcp-image{
        position: relative;
        /* display: flex; */
        /* justify-content: center; */
        width: 100%;
        height: 50%;
        padding: 0.5rem;
        /* border: 1px solid green; */
        overflow: hidden;
    }
    .hcp-image img{
        width: 100%;
        /* max-width: 100%; */
        height: unset;
        /* max-height: 100%; */
        transition: 0.2s;
        border-radius: 10px;
    }
    .img-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .hcp-detail{
        height: 50%;
        padding: 0.5rem;
    }
    .hcp-detail h3{
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: 700;
        color: #1B4674;
    }
    .hcp-detail p{
        font-size: 1rem;
        color: var(--navy);
    }
    .hcp-detail button{
        border: none;
        border-radius: 50px;
        background: none;
        color: var(--navy);
        padding: 0.5rem 2rem 0.5rem 2rem;
        /* padding-left: 2rem; */
        /* padding-right: 2rem; */
        font-style: italic;
    }
    /* .hcp-description{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.432);
        color: var(--white);
        padding-left: 5%;
        padding-right: 5%;
    } */
    @media only screen and (max-width: 1500px){
        .hdf-image{
            height: 27rem;
        }
    }
    @media only screen and (max-width: 1200px){
        .hc-part{
            min-width: 50%;
        }
    }
    @media only screen and (max-width: 1050px){
        /* .hdf-image{
            height: 26rem;
        } */
        .hh-left{
            padding-top: 2rem;
            padding-right: 2rem;
        }
        .hh-left h1{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 550px){
        /*  */
    }
    @media only screen and (max-width: 610px){
        .hc-part{
            min-width: 100%;
        }
    }
</style>