<template>
    <AboutTopComponent/>
    <HistoryComponent/>
    <VisionMissionComponent/>
    <PhilosophyComponent/>
    <DisclaimerComponent/>
    <AwardsComponent/>
</template>

<script setup>
    import AboutTopComponent from '@/components/about/AboutTopComponent.vue';
    import HistoryComponent from '@/components/about/HistoryComponent.vue';
    import VisionMissionComponent from '@/components/about/VisionMissionComponent.vue';
    import PhilosophyComponent from '@/components/about/PhilosophyComponent.vue';
    import DisclaimerComponent from '@/components/about/DisclaimerComponent.vue';
    import AwardsComponent from '@/components/about/AwardsComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Kenali Kami Lebih Dekat',
            description: 'RSIA Melinda Bandung didirikan pada tahun 2004 oleh dr, Susan Melinda, Sp.OG. Kami memahami Ibu dan anak agar mendapatkan pelayanan kesehatan yang baik dengan penuh kasih sayang.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>






