<template>
    <LinkTopComponent/>
    <LinkBottomComponent/>
</template>

<script setup>
    import LinkTopComponent from '@/components/link/LinkTopComponent.vue';
    import LinkBottomComponent from '@/components/link/LinkBottomComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Group RS Melinda',
            description: 'Terdapat beberapa website yang merupakan keluarga dari Rumah Sakit Melinda yang menyajikan info menarik, tetap ikuti perkembangan kami, dengan mengakses link tautan di bawah ini untuk mengetahui informasi dan layanan terbaru yang kami sediakan.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>