import { defineStore } from "pinia";
import axiosMain from "../axiosmain.js";
import { clearInput, clearCheckBox } from "@/functions/general.js";
import { showEventnotif } from "@/functions/event.js";

export const useEventStore = defineStore("event", {
    state: () => ({
        // ==================== states used on event page and its relation
        // eventFormStatus: true,
        events: null,
        eventDetail: null,
        eventForm: null,
        webinar: null,
        eventLoader: null,
        userAgent: navigator.userAgent
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on event page and its relation
        async setEvents(){
            try{
                const response = await axiosMain.get(`events`);
                this.events = response.data.data;
                console.log(response);
            }catch(error){
                console.log(error.response);
            }
        },
        async setEventDetail(slug){
            try{
                const response = await axiosMain.get(`events/` + slug);
                this.eventDetail = response.data.data;
            }catch(error){
                console.log(error.response);
            }
        },
        async setEventForm(slug){
            try{
                const response = await axiosMain.get(`events/form/` + slug);
                this.eventForm = response.data.data;
                // console.log(this.eventForm);
                // const date = new Date();
                // const dateClose = new Date(response.data.register_close);
                // if(date.getTime() < dateClose.getTime()){
                //     this.eventFormStatus = true;
                // }else{
                //     this.eventFormStatus = false;
                // }
                // console.log(this.eventFormStatus);
                // console.log('Now : ' + date.getTime() + ' - Close : ' + dateClose.getTime());
                // console.log(this.eventForm);
                // const contacts = [
                //     {
                //         name: 'Erna',
                //         phone: '+6281282013039',
                //         text: 'Halo, saya sudah daftar Mom and Baby Class'
                //     },
                //     {
                //         name: 'Indah',
                //         phone: '+6285100591928',
                //         text: 'Halo, saya sudah daftar Mom and Baby Class'
                //     }
                // ];
                // this.webinar = {contacts: contacts};
                // showEventnotif('', '' + '<br/>Silakan mengonfirmasi pendaftaran anda ke Contact Person kami di bawah ini<br/>', 60000);
            }catch(error){
                console.log(error.response);
            }
        },
        async setWebinar(slug, params){
            // console.log(params);
            this.setEventLoader(1);
            try{
                const contacts = [
                    {
                        name: 'Erna',
                        phone: '+6281282013039',
                        text: 'Halo, saya sudah daftar Mom and Baby Class'
                    },
                    {
                        name: 'Indah',
                        phone: '+6285100591928',
                        text: 'Halo, saya sudah daftar Mom and Baby Class'
                    }
                ];
                const response = await axiosMain.post(`events/form/` + slug, params, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.webinar = response.data;
                this.webinar.contacts = contacts;
                showEventnotif('', response.data.message + '<br/>Silakan mengonfirmasi pendaftaran anda ke Contact Person kami di bawah ini<br/>', 60000);
                clearInput('input');
                clearCheckBox('checkbox');
                // console.log(response);
                this.setEventLoader(null);
            }catch(error){
                this.setEventLoader(null);
                console.log(error.response);
                if(error.response.data?.errors?.phone){
                    error.response.data.errors.phone.forEach(element => {
                        showEventnotif('', 'Gagal<br/>'+ element +'<br/>', 5000);
                    });
                }
            }
        },
        setEventLoader(value){
            this.eventLoader = value;
        }
    }
});