<template>
    <RoomTopComponent/>
    <RoomBoxComponent/>
</template>

<script setup>
    import RoomTopComponent from '@/components/rooms/RoomTopComponent.vue';
    import RoomBoxComponent from '@/components/rooms/RoomBoxComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Suite dan Kamar lainnya',
            description: 'RSIA Melinda Bandung menghadirkan berbagai tipe kamar yang modern dan fasilitas yang lengkap. RSIA Melinda Bandung menawarkan kepada para pasien dan keluarga dengan fasilitas yang dibutuhkan sehingga pasien dapat menikmati kenyamanan dalam melakukan pemulihan dengan maksimal.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>

