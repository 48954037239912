<template>
    <EventDetailComponent/>
</template>

<script setup>
    import EventDetailComponent from '@/components/event/EventDetailComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>