<template>
    <EventComponent/>
</template>

<script setup>
    import EventComponent from '@/components/event/EventComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>