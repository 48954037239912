<template>
    <section>
        <div class="section-inner" v-if="body">
            <div class="si-picture">
                <div class="si-picture-img">
                    <div class="spi-inner">
                        <img :src="body.vision_mission.img_url" alt="rsia melinda"/>
                    </div>
                </div>
            </div>
            <div class="si-text">
                <div class="cov-strip">
                    <div class="strip"></div>
                </div>
                <div class="si-text-summary">
                    <h3>{{ body.vision_mission.sub_title }}</h3>
                    <h2>{{ body.vision_mission.title }}</h2>
                    <div class="std-description" v-html="body.vision_mission.description"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useAboutStore } from '@/stores/about';
    const body = computed(() => useAboutStore().body);
</script>

<style scoped>
    section{
        position: relative;
        padding: 2.5rem 10% 2.5rem 10%;
        overflow: hidden;
        background: var(--navy);
    }
    .section-inner{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
    }
    .si-picture{
        width: 50%;
    }
    .si-picture-img{
        width: 100%;
        /* padding: 0 0 0 0; */
        overflow: hidden;
    }
    .spi-inner{
        position: relative;
        width: 100%;
        /* height: 600px; */
        /* background: var(--navy); */
    }
    .spi-inner img{
        /* position: absolute;
        top: 2.5rem;
        left: 10%; */
        width: 100%;
    }
    .si-text{
        width: 50%;
        padding: 0 2rem 0 0;
        color: var(--white);
    }
    .si-text-summary h3{
        margin: 0;
        font-size: 1.1rem;
        color: var(--primary);
    }
    .si-text-summary h2{
        font-size: 2rem;
        color: var(--white);
        margin: 1rem 0 1rem 0;
    }
    .cov-strip{
        display: flex;
        justify-content: left;
        padding: 0 0 0 0;
        transition: 0.2s;
        margin: 0 0 1rem 0;
    }
    .strip{
        width: 5%;
        height: 0.5rem;
        background: var(--white);
        transition: 0.2s;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .si-picture{
            width: 100%;
        }
        .si-text{
            width: 100%;
            padding: 2.5rem 1rem 0 0;
        }
    }
    /*  */
</style>