<template>
    <div class="text-loader">
      <p>Website Sedang Dalam Masa Pembaharuan </p>
      <p>Halaman Website RSIA Melinda sudah berganti ke rsiamelinda.com </p>
      <div class="lds-ripple"><div></div><div></div></div>
    </div>
</template>

<script setup>
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'RSIA Melinda Bandung - Pengarahan ke Website baru',
            description: 'Anda akan diarahkan ke Website RSIA Melinda yang baru'
        });
    });
    scrollTo(0, 0);
    redirect(5000);
    function redirect(ms) {
        setTimeout(function(){
            location.href = 'https://rsiamelinda.com';
        }, ms);
    }
</script>

<style scoped>
.text-loader{
  width: 100%;
  min-height: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  padding: 1rem;
  text-align: center;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid var(--primary);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>