import router from "@/router";
import { cmcIndex } from "./home";
export function pageScrollTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
export function showNotification(title, message, milisecond, bg){
    const date = new Date();
    const time = date.getTime();
    const nt = document.getElementById('notification');
    const tt = document.getElementById('notification-title');
    const mg = document.getElementById('notification-message');
    var background;
    nt.style.display = 'none';
    if(bg){
        background = bg;
    }else{
        background = 'rgb(76, 155, 230)';
    }
    nt.style.background = background;
    nt.setAttribute('time', time);
    setTimeout(function(){
        tt.innerHTML = title;
        mg.innerHTML = message;
        nt.style.display = 'block';
        setTimeout(function(){
            if(nt.getAttribute('time') == time){
                nt.style.display = 'none';
            }
        }, milisecond);
    }, 0);
}
export function setCookie(cname, cvalue, exdays){
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}
export function showModal(id){
    const modal = document.getElementById(id);
    modal.classList.add('modal-active');
}
export function hideModals(event){
    var modal = document.getElementsByClassName('modal');
    for(var i = 0; i < modal.length; i++){
        if(event.target == modal[i]){
            modal[i].style.display = 'none';
        }
    }
}
export function hideModal(id){
    const modal = document.getElementById(id);
    modal.classList.remove('modal-active');
}
export function showLogin(){
    const lf = document.getElementById('navbar-login-form');
    // console.log(lf.children[0]);
    if(lf.style.display == 'block'){
        if(lf.children[0].children[1].scrollLeft > 0){
            lf.children[0].children[1].scrollLeft = 0;
        }else{
            lf.style.display = 'none';
        }
    }else{
        lf.style.display = 'block';
    }
    lf.children[0].children[1].scrollLeft = 0;
}
export function hideLogin(){
    const lf = document.getElementById('navbar-login-form');
    lf.style.display = 'none';
}
export function goTo(route){
    router.push(route);
}
export function showTab(button, btnid, hide, show, btactivename, ctactivename){
    var i;
    const bt = document.getElementsByClassName(button);
    const cl = document.getElementsByClassName(hide);
    for(i = 0; i < bt.length; i++){
        bt[i].classList.remove(btactivename);
    }
    for(i = 0; i < cl.length; i++){
        cl[i].classList.remove(ctactivename);
    }
    document.getElementById(btnid).classList.add(btactivename);
    document.getElementById(show).classList.add(ctactivename);
}
export function wordsLimit(words, max){
    if(words.length > max) words = words.substring(0, max) + '...';
    return words;
}
export function removeHTML(html){
    var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        return text;
}
export function number_format(number, decimals, dec_point, thousands_sep){
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
export function navbarMenuSelect(id, event){
    const button = document.getElementById(id);
    const arrow = button.children[1].children[0];
    if(hasTouch()){
        if(event == 'click'){
            if(button.nextElementSibling.classList.contains('npa-option-active')){
                button.nextElementSibling.classList.remove('npa-option-active');
                button.nextElementSibling.style.height = 0;
                arrow.style.transform = 'rotate(0)';
            }else{
                button.nextElementSibling.classList.add('npa-option-active');
                button.nextElementSibling.style.height = button.nextElementSibling.scrollHeight + 'px';
                arrow.style.transform = 'rotate(180deg)';
            }
        }
    }
    if(!hasTouch()){
        if(event == 'enter'){
            button.nextElementSibling.classList.add('npa-option-active');
            button.nextElementSibling.style.height = button.nextElementSibling.scrollHeight + 'px';
            arrow.style.transform = 'rotate(180deg)';
        }
        if(event == 'leave'){
            button.nextElementSibling.classList.remove('npa-option-active');
            button.nextElementSibling.style.height = 0;
            arrow.style.transform = 'rotate(0)';
        }
    }
}
export function topSearchSelect(id){
    const button = document.getElementById(id);
    const arrow = button.children[2].children[0].children[0];
    if(button.nextElementSibling.classList.contains('float-active')){
        button.nextElementSibling.classList.remove('float-active');
        arrow.style.transform = 'rotate(0)';
    }else{
        button.nextElementSibling.classList.add('float-active');
        arrow.style.transform = 'rotate(180deg)';
    }
}
export function hasTouch() {
    return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
}
export function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}
export const createSlug = str => str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
export function scheduleHour(time){
    if(time){
        return time.slice(0, 5);
    }
    // return time.slice(0, 5).replace(':', ' : ');
    // console.log(time);
}
export function accordionToggle(id){
    const bt = document.getElementById(id);
    const pn = bt.nextElementSibling;
    if(pn.style.maxHeight){
        bt.children[1].children[0].style.transform = 'rotate(0deg)';
        pn.style.maxHeight = null;
    }else{
        bt.children[1].children[0].style.transform = 'rotate(180deg)';
        pn.style.maxHeight = pn.scrollHeight + 'px';
    }
}
export function optionToggle(button){
    const float = document.getElementsByClassName('float');
    for(var i = 0; i < float.length; i++){
        const thisbt = float[i].previousElementSibling;
        if(thisbt.getAttribute('id') != button){
            float[i].classList.remove('float-active');
            thisbt.children[2].classList.remove('float-icon-active');
        }
    }
    const bt = document.getElementById(button);
    if(bt.nextElementSibling.classList.contains('float-active')){
        bt.nextElementSibling.classList.remove('float-active');
        bt.children[2].classList.remove('float-icon-active');
    }else{
        bt.nextElementSibling.classList.add('float-active');
        bt.children[2].classList.add('float-icon-active');
    }
}
export function selectOption(sp, option){
    document.getElementById(option.input).value = sp.id;
    document.getElementById(option.name).innerHTML = sp.name;
}
export function clearInput(ic){
    const input = document.getElementsByClassName(ic);
    for(var i = 0; i < input.length; i++){
        input[i].value = '';
    }
}
export function clearCheckBox(ic){
    const checkbox = document.getElementsByClassName(ic);
    for(var i = 0; i < checkbox.length; i++){
        checkbox[i].checked = false;
    }
}
export function clickElement(id){
    document.getElementById(id).click();
}
export function roughSizeOfObject(object){
    var objectList = [];
    var stack = [ object ];
    var bytes = 0;
    while(stack.length){
        var value = stack.pop();
        if (typeof value === 'boolean'){
            bytes += 4;
        }else if (typeof value === 'string'){
            bytes += value.length * 2;
        }else if (typeof value === 'number'){
            bytes += 8;
        }else if(typeof value === 'object' && objectList.indexOf(value) === -1){
            objectList.push(value);
            for(var i in value){
                stack.push(value[ i ]);
            }
        }
    }
    return bytes;
}
export function setPageData(data){
    if(data.title){
        document.title = data.title;
        document.querySelector('meta[name="title"]').setAttribute("content", data.title);
    }
    if(data.description){
        document.querySelector('meta[name="description"]').setAttribute("content", data.description);
    }
    if(data.ogData){
        if(data.ogData.title){
            if(document.querySelector('meta[property="og:title"]')){
                document.querySelector('meta[property="og:title"]').setAttribute("content", data.ogData.title);
            }else{
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('property', 'og:title');
                metaTag.content = data.ogData.title;
                document.head.appendChild(metaTag);
            }
        }
        if(data.ogData.type){
            if(document.querySelector('meta[property="og:type"]')){
                document.querySelector('meta[property="og:type"]').setAttribute("content", data.ogData.type);
            }else{
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('property', 'og:type');
                metaTag.content = data.ogData.type;
                document.head.appendChild(metaTag);
            }
        }
        if(data.ogData.url){
            if(document.querySelector('meta[property="og:url"]')){
                document.querySelector('meta[property="og:url"]').setAttribute("content", data.ogData.url);
            }else{
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('property', 'og:url');
                metaTag.content = data.ogData.url;
                document.head.appendChild(metaTag);
            }
        }
        if(data.ogData.image){
            if(document.querySelector('meta[property="og:image"]')){
                document.querySelector('meta[property="og:image"]').setAttribute("content", data.ogData.image);
            }else{
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('property', 'og:image');
                metaTag.content = data.ogData.image;
                document.head.appendChild(metaTag);
            }
        }
        if(data.ogData.description){
            if(document.querySelector('meta[property="og:description"]')){
                document.querySelector('meta[property="og:description"]').setAttribute("content", data.ogData.description);
            }else{
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('property', 'og:description');
                metaTag.content = data.ogData.description;
                document.head.appendChild(metaTag);
            }
        }
    }
}
export function articleIndex(content){
    const articles = [];
    var currentArticle = -1;
    var lasth2;
    var lasth3;
    var lasth4;
    var lasth5;
    var lasth6;
    var thisid;
    var i;
    for(i = 0; i < content.children.length; i++){
        if(content.children[i].tagName == 'H1'){
            thisid = 'index-' + content.children[i].tagName + '-' + (currentArticle + 1);
            articles.push(
                {
                    name: 'H1',
                    value: content.children[i].innerHTML,
                    id: thisid,
                    children: []
                }
            );
            currentArticle = currentArticle + 1;
            lasth2 = -1;
        }
        if(content.children[i].tagName == 'H2'){
            thisid = 'index-' + content.children[i].tagName + '-' + currentArticle + '-' + (lasth2 + 1);
            articles[currentArticle].children.push(
                {
                    name: 'H2',
                    value: content.children[i].innerHTML,
                    id: thisid,
                    children: []
                }
            );
            lasth2 = lasth2 + 1;
            lasth3 = -1;
        }
        if(content.children[i].tagName == 'H3'){
            thisid = 'index-' + content.children[i].tagName + '-' + currentArticle + '-' + lasth2 + '-' + (lasth3 + 1);
            articles[currentArticle].children[lasth2].children.push(
                {
                    name: 'H3',
                    value: content.children[i].innerHTML,
                    id: thisid,
                    children: []
                }
            );
            lasth3 = lasth3 + 1;
            lasth4 = -1;
        }
        if(content.children[i].tagName == 'H4'){
            thisid = 'index-' + content.children[i].tagName + '-' + currentArticle + '-' + lasth2 + '-' + lasth3 + '-' + (lasth4 + 1);
            articles[currentArticle].children[lasth2].children[lasth3].children.push(
                {
                    name: 'H4',
                    value: content.children[i].innerHTML,
                    id: thisid,
                    children: []
                }
            );
            lasth4 = lasth4 + 1;
            lasth5 = -1;
        }
        if(content.children[i].tagName == 'H5'){
            thisid = 'index-' + content.children[i].tagName + '-' + currentArticle + '-' + lasth2 + '-' + lasth3 + '-' + lasth4 + '-' + (lasth5 + 1);
            articles[currentArticle].children[lasth2].children[lasth3].children[lasth4].children.push(
                {
                    name: 'H5',
                    value: content.children[i].innerHTML,
                    id: thisid,
                    children: []
                }
            );
            lasth5 = lasth5 + 1;
            lasth6 = -1;
        }
        if(content.children[i].tagName == 'H6'){
            thisid = 'index-' + content.children[i].tagName + '-' + currentArticle + '-' + lasth2 + '-' + lasth3 + '-' + lasth4 + '-' + lasth5 + '-' + (lasth6 + 1);
            articles[currentArticle].children[lasth2].children[lasth3].children[lasth4].children[lasth5].children.push(
                {
                    name: 'H6',
                    value: content.children[i].innerHTML,
                    id: thisid,
                    children: []
                }
            );
            lasth6 = lasth6 + 1;
        }
        content.children[i].setAttribute('id', thisid);
    }
    // console.log(articles);
    return articles;
}
export function createHtmlIndex(data){
    var cover = document.createElement('div');
    cover.classList.add('cover-index');
    for(var i = 0; i < data.length; i++){
        var coverh1 = document.createElement('div');
        var elh1 = document.createElement('div');
        var elh1text = document.createTextNode(data[i].value);
        elh1.setAttribute('target', data[i].id);
        elh1.classList.add('index-main-title');
        coverh1.classList.add('cover-index-h1');
        elh1.appendChild(elh1text);
        coverh1.appendChild(elh1);
        elh1.addEventListener('click', function(){
            document.getElementById(this.getAttribute('target')).scrollIntoView();
            cmcIndex();
        });
        if(data[i].children.length){
            for(var j = 0; j < data[i].children.length; j++){
                var coverh2 = document.createElement('div');
                var elh2 = document.createElement(data[i].children[j].name);
                var elh2text = document.createTextNode(data[i].children[j].value);
                elh2.setAttribute('target', data[i].children[j].id);
                coverh2.classList.add('cover-index-h2');
                elh2.appendChild(elh2text);
                coverh2.appendChild(elh2);
                elh2.addEventListener('click', function(){
                    document.getElementById(this.getAttribute('target')).scrollIntoView();
                    cmcIndex();
                });
                if(data[i].children[j].children.length){
                    for(var k = 0; k < data[i].children[j].children.length; k++){
                        var coverh3 = document.createElement('div');
                        var elh3 = document.createElement(data[i].children[j].children[k].name);
                        var elh3text = document.createTextNode(data[i].children[j].children[k].value);
                        elh3.setAttribute('target', data[i].children[j].children[k].id);
                        coverh3.classList.add('cover-index-h3');
                        elh3.appendChild(elh3text);
                        coverh3.appendChild(elh3);
                        elh3.addEventListener('click', function(){
                            document.getElementById(this.getAttribute('target')).scrollIntoView();
                            cmcIndex();
                        });
                        // coverh2.appendChild(coverh3);
                        if(data[i].children[j].children[k].children.length){
                            for(var l = 0; l < data[i].children[j].children[k].children.length; l++){
                                var coverh4 = document.createElement('div');
                                var elh4 = document.createElement(data[i].children[j].children[k].children[l].name);
                                var elh4text = document.createTextNode(data[i].children[j].children[k].children[l].value);
                                elh4.setAttribute('target', data[i].children[j].children[k].children[l].id);
                                coverh4.classList.add('cover-index-h4');
                                elh4.appendChild(elh4text);
                                coverh4.appendChild(elh4);
                                elh4.addEventListener('click', function(){
                                    document.getElementById(this.getAttribute('target')).scrollIntoView();
                                    cmcIndex();
                                });
                                // coverh3.appendChild(coverh4);
                                if(data[i].children[j].children[k].children[l].children.length){
                                    for(var m = 0; m < data[i].children[j].children[k].children[l].children.length; m++){
                                        var coverh5 = document.createElement('div');
                                        var elh5 = document.createElement(data[i].children[j].children[k].children[l].children[m].name);
                                        var elh5text = document.createTextNode(data[i].children[j].children[k].children[l].children[m].value);
                                        elh5.setAttribute('target', data[i].children[j].children[k].children[l].children[m].id);
                                        coverh5.classList.add('cover-index-h5');
                                        elh5.appendChild(elh5text);
                                        coverh5.appendChild(elh5);
                                        elh5.addEventListener('click', function(){
                                            document.getElementById(this.getAttribute('target')).scrollIntoView();
                                            cmcIndex();
                                        });
                                        // coverh4.appendChild(coverh5);
                                        if(data[i].children[j].children[k].children[l].children.length){
                                            for(var n = 0; n < data[i].children[j].children[k].children[l].children[m].children.length; n++){
                                                var coverh6 = document.createElement('div');
                                                var elh6 = document.createElement(data[i].children[j].children[k].children[l].children[m].children[n].name);
                                                var elh6text = document.createTextNode(data[i].children[j].children[k].children[l].children[m].children[n].value);
                                                elh6.setAttribute('target', data[i].children[j].children[k].children[l].children[m].children[n].id);
                                                coverh6.classList.add('cover-index-h6');
                                                elh6.appendChild(elh6text);
                                                coverh6.appendChild(elh6);
                                                elh6.addEventListener('click', function(){
                                                    document.getElementById(this.getAttribute('target')).scrollIntoView();
                                                    cmcIndex();
                                                });
                                                coverh5.appendChild(coverh6);
                                            }
                                        }
                                        coverh4.appendChild(coverh5);
                                    }
                                }
                                coverh3.appendChild(coverh4);
                            }
                        }
                        coverh2.appendChild(coverh3);
                    }
                }
                coverh1.appendChild(coverh2);
            }
        }
        cover.appendChild(coverh1);
    }
    return cover;
}
export function dateFormatIndonesia(data){
    const mt = ['Januari', 'Pebruari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    const date = new Date(data);
    return date.getDate() + ' ' + mt[date.getMonth()] + ' ' + date.getFullYear();
}
// export function containsObject(obj, list) {
//     console.log(obj);
//     var i;
//     for (i = 0; i < list.length; i++) {
//         if (list[i] === obj) {
//             console.log(true);
//             return true;
//         }
//     }
//     console.log(false);
//     return false;
// }





