<template>
    <RoomDetailComponent/>
    <TestimonyComponent/>
</template>

<script setup>
    import RoomDetailComponent from '@/components/rooms/RoomDetailComponent.vue';
    import TestimonyComponent from '@/components/rooms/TestimonyComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>


