export const specializations = [
    {
        id: '85b88b14-d11e-4752-903f-b1ff604adc65',
        slug: '',
        name: 'Dokter Spesialis Obstetri dan Ginekologi',
        sort: '',
        doctors: []
    },
    {
        id: '10aff08e-b070-409b-9657-bc60537a697f',
        slug: '',
        name: 'Dokter Spesialis Kandungan Konsultan Fetomaternal',
        sort: '',
        doctors: []
    },
    {
        id: '5ad30994-c86e-4cb9-aee5-1281ad1d9c9c',
        slug: '',
        name: 'Dokter Spesialis Anak',
        sort: '',
        doctors: []
    },
    {
        id: '7b211160-2140-4add-93bb-50cc73395ec1',
        slug: '',
        name: 'Dokter Spesialis Anak Sub Spesialis Neurologi',
        sort: '',
        doctors: []
    },
    {
        id: '50b41c4d-b20a-4c2b-b282-cbbd90515419',
        slug: '',
        name: 'Dokter Spesialis Gizi',
        sort: '',
        doctors: []
    },
    {
        id: '35a433f3-c270-4607-b80a-6ec343887bc4',
        slug: '',
        name: 'Dokter Spesialis Bedah Onkologi',
        sort: '',
        doctors: []
    },
    {
        id: '230c0b8b-e2ef-413f-97dd-c592deb6e4a9',
        slug: '',
        name: 'Dokter Spesialis Bedah Plastik',
        sort: '',
        doctors: []
    },
    {
        id: 'f47c724d-6921-4fde-ba96-9a23e16b8081',
        slug: '',
        name: 'Dokter Spesialis Bedah Umum',
        sort: '',
        doctors: []
    },
    {
        id: '27bbd185-0a20-438b-a21f-795ffd24354c',
        slug: '',
        name: 'Dokter Spesialis Kulit Kelamin',
        sort: '',
        doctors: []
    },
    {
        id: 'f77e2474-7739-4c96-9af3-ff8602f62eaf',
        slug: '',
        name: 'Dokter Spesialis THT',
        sort: '',
        doctors: []
    },
    {
        id: '9b4795b4-ddb7-4afd-8bd3-46a8c8602df7',
        slug: '',
        name: 'Dokter Spesialis Gigi',
        sort: '',
        doctors: []
    },
    {
        id: 'a7685194-7fd2-464c-b890-32778a478495',
        slug: '',
        name: 'Dokter Spesialis Kedokteran Fisik & Rehabilitasi',
        sort: '',
        doctors: []
    },
    {
        id: '240b60de-2ab1-4f95-80ab-568672ac714c',
        slug: '',
        name: 'Dokter Spesialis Penyakit Dalam',
        sort: '',
        doctors: []
    },
    {
        id: 'bfdd8b1a-0a33-43fc-9678-6b87d3c77a22',
        slug: '',
        name: 'Dokter Spesialis Anestesi',
        sort: '',
        doctors: []
    },
    {
        id: 'c3945e46-f043-4194-b42d-6cd61cab8fe4',
        slug: '',
        name: 'Klinik Laktasi',
        sort: '',
        doctors: []
    },
    {
        id: '7e223de2-4b82-48f6-a31f-81e3322de43d',
        slug: '',
        name: 'Dokter Umum IGD',
        sort: '',
        doctors: []
    },
];