<template>
    <section>
        <div class="section-inner" v-if="header">
            <div class="si-top">
                <div class="si-text">
                    <h2>{{ header.sub_title }}</h2>
                    <h1>{{ header.title }}</h1>
                    <div class="si-text-description" v-html="header.description"></div>
                </div>
                <div class="si-image">
                    <img :src="header.img_url" alt="rsia melinda"/>
                </div>
            </div>
        </div>
        <ContactTopSkeleton v-else/>
    </section>
</template>

<script setup>
    import ContactTopSkeleton from '@/components/skeletons/sections/ContactTopSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useContactStore } from '@/stores/contact';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'RSIA Melinda - Hubungi Kami',
            description: 'Dengan senang hati resepsionis kami yang profesional akan membantu serta memberikan informasi terkait informasi dan masalah yang ingin anda tanyakan.'
        });
    });
    const header = computed(() => useContactStore().header);
    useContactStore().setContact();
</script>

<style scoped>
    section{
        transition: 0.2s;
        padding: 0 0 2.5rem 10%;
        background: var(--cream-bg);
    }
    .section-inner{
        position: relative;
        width: 100%;
        height: 500px;
        display: flex;
        flex-wrap: wrap;
        transition: 0.2s;
    }
    .si-top{
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .si-text{
        width: 50%;
        padding: 2.5rem 2rem 0 0;
        transition: 0.2s;
    }
    .si-text h2{
        font-size: 1.1rem;
        color: var(--primary);
        margin: 0;
    }
    .si-text h1{
        font-size: 2rem;
        color: var(--navy);
        margin: 1rem 0 1rem 0;
        transition: 0.2s;
    }
    .si-text-description{
        padding: 0 0 5rem 0;
        color: var(--grey);
    }
    .si-image{
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: 0.2s;
    }
    .si-image img{
        height: 100%;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 0 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        section{
            padding: 0 5% 0 5%;
        }
        .section-inner{
            height: auto;
        }
        .si-text{
            width: 100%;
            padding: 2.5rem 0 0 0;
        }
        .si-image{
            width: 0;
            height: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 0 2.5% 0 2.5%;
        }
        .si-text h1{
            font-size: 1.5rem;
        }
    }
</style>