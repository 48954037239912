<template>
    <section>
        <div class="section-inner" v-if="body">
            <div class="si-picture">
                <div class="si-picture-img">
                    <div class="spi-inner">
                        <img :src="body.award.img_url" alt="rsia melinda"/>
                    </div>
                </div>
            </div>
            <div class="si-text">
                <div class="cov-strip">
                    <div class="strip"></div>
                </div>
                <div class="si-text-summary">
                    <h3>{{ body.award.sub_title }}</h3>
                    <h2>{{ body.award.title }}</h2>
                    <div class="std-description" v-html="body.award.description"></div>
                    <div class="award-list">
                        <div class="award-list-inner">
                            <div class="ali-part" v-for="(award, index) in body.award_lists" :key="award">
                                <div class="ali-button" :id="'ali-button-' + index" @click="accordionToggle('ali-button-' + index);">
                                    <div class="ali-button-text">
                                        <span>{{ award.year }}</span>
                                    </div>
                                    <div class="ali-button-icon">
                                        <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                    </div>
                                </div>
                                <div class="ali-content">
                                    <div class="ali-content-inner" v-html="award.description"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useAboutStore } from '@/stores/about';
    import { accordionToggle } from '@/functions/general';
    const body = computed(() => useAboutStore().body);
</script>

<style scoped>
    section{
        position: relative;
        padding: 2.5rem 10% 2.5rem 10%;
        overflow: hidden;
        /* background: var(--navy); */
    }
    .section-inner{
        display: flex;
        flex-wrap: wrap;
        /* flex-direction: row-reverse; */
    }
    .si-picture{
        width: 50%;
    }
    .si-picture-img{
        width: 100%;
        /* padding: 0 0 0 0; */
        overflow: hidden;
    }
    .spi-inner{
        position: relative;
        width: 100%;
        /* height: 600px; */
        /* background: var(--navy); */
    }
    .spi-inner img{
        /* position: absolute;
        top: 2.5rem;
        left: 10%; */
        width: 100%;
    }
    .si-text{
        width: 50%;
        padding: 0 0 0 2rem;
        color: var(--grey);
    }
    .cov-strip{
        display: flex;
        justify-content: left;
        padding: 0 0 0 0;
        transition: 0.2s;
        margin: 0 0 1rem 0;
    }
    .strip{
        width: 5%;
        height: 0.5rem;
        background: var(--navy);
        transition: 0.2s;
    }
    .si-text-summary h3{
        margin: 0;
        font-size: 1.1rem;
        color: var(--primary);
    }
    .si-text-summary h2{
        font-size: 2rem;
        color: var(--navy);
        margin: 1rem 0 1rem 0;
    }
    .award-list{
        padding: 2rem 0 0 0;
    }
    .ali-part{
        background: var(--smoke);
        padding: 0.5rem;
    }
    .ali-button{
        display: flex;
        cursor: pointer;
        user-select: none;
        /* background: var(--smoke); */
        padding: 0.5rem;
    }
    .ali-button-text{
        width: 90%;
    }
    .ali-button-icon{
        width: 10%;
        display: flex;
        justify-content: right;
        align-items: center;
    }
    .ali-button-icon img{
        max-width: 100%;
        transition: 0.2s;
    }
    .ali-content{
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }
    .ali-content-inner{
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        padding: 0.5rem;
        margin: 0.5rem;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .si-picture{
            width: 100%;
        }
        .si-text{
            width: 100%;
            padding: 2.5rem 0 0 0;
        }
    }
</style>