<template>
    <section>
        <div class="section-content">
            <div class="sc-inner" v-if="event">
                <div class="sci-top">
                    <div class="st-head">
                        <p class="small-title">RSIA Melinda</p>
                        <h1 class="main-title">{{ event.name }}</h1>
                    </div>
                    <div class="st-body">
                        <div class="st-img">
                            <img :src="event.image_url" alt="rsia melinda" />
                        </div>
                        <div class="st-detail">
                            <div class="st-detail-head">
                                <!-- <h2>{{ event.name }}</h2> -->
                            </div>
                            <div class="st-detail-body">
                                <div class="sdb-table">
                                    <div class="sdb-column">
                                        <div class="sdb-column-param">
                                            <p>Pendaftaran Dibuka</p>
                                        </div>
                                        <div class="sdb-column-value">
                                            <p>{{ epochDMY(event.register_open) }}</p>
                                        </div>
                                    </div>
                                    <div class="sdb-column">
                                        <div class="sdb-column-param">
                                            <p>Pendaftaran Ditutup</p>
                                        </div>
                                        <div class="sdb-column-value">
                                            <p>{{ epochDMY(event.register_close) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sci-description">
                    <!-- <p>{{ event.description }}</p> -->
                    <div v-html="event.description"></div>
                    <router-link :to="'/event/form/' + useRoute().params.slug">
                        <button>Daftar</button>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { computed } from '@vue/reactivity';
    import { useEventStore } from '@/stores/event';
    const event = computed(() => useEventStore().eventDetail);
    function epochDMY(ts){
        const months = ["Januari", "Pebruari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        var dt = new Date(ts);
        var d = dt.getDate();
        var m = months[dt.getMonth()];
        var y = dt.getFullYear();
        return d + ' ' + m +' ' + y;
    }
    useEventStore().setEventDetail(useRoute().params.slug);
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .small-title{
        color: #75C6EF;
        font-weight: 600;
        font-size: 1.125rem;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: 0.2s;
        line-height: 1.2;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .sc-inner{
        width: 100%;
        padding-top: 2rem;
        transition: 0.2s;
    }
    .sci-top{
        width: 100%;
        transition: 0.2s;
    }
    .st-body{
        display: flex;
        flex-wrap: wrap;
        padding-top: 2rem;
    }
    .st-img{
        width: 50%;
        margin-bottom: 2rem;
        transition: 0.2s;
    }
    .st-img img{
        width: auto;
        width: 100%;
        transition: 0.2s;
    }
    .st-detail{
        width: 50%;
        padding-left: 1rem;
        text-align: justify;
        transition: 0.2s;
    }
    .st-detail-head h2{
        margin: 0;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .sdb-table{
        display: flex;
        flex-wrap: wrap;
        /* border: 1px solid green; */
    }
    .sdb-column{
        width: 50%;
        transition: 0.2s;
        margin-bottom: 1rem;
    }
    .sdb-column-param{
        font-size: 1.1rem;
        font-weight: 700;
    }
    .sci-description{
        width: 100%;
        transition: 0.2s;
    }
    .sci-description button{
        border: none;
        border-radius: 50px;
        background: #75C6EF;
        color: #ffffff;
        padding: 0.5rem 2rem 0.5rem 2rem;
        /* padding-left: 1rem;
        padding-right: 1rem; */
        cursor: pointer;
    }
    @media only screen and (max-width: 1500px){
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
        .main-title{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .main-title{
            font-size: 2rem;
        }
        .sci-top{
            width: 100%;
        }
        .st-img{
            width: 100%;
        }
        .st-img img{
            width: 100%;
        }
        .st-detail{
            width: 100%;
            padding-left: 0;
        }
        .sci-description{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 2rem;
            padding-left: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-top: 1rem;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sdb-column{
            width: 50%;
        }
        .sci-description{
            padding-top: 1rem;
        }
    }
    @media only screen and (max-width: 370px){
        .sdb-column{
            width: 100%;
        }
    }
</style>