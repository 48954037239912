<template>
    <section>
        <div class="section-inner" v-if="room">
            <div class="si-picture">
                <div class="si-picture-img">
                    <img :src="room.comment.img_url" alt="rsia melinda"/>
                </div>
            </div>
            <div class="si-text">
                <div class="cov-strip">
                    <div class="strip"></div>
                </div>
                <h3>{{ room.comment.sub_title }}</h3>
                <h2>{{ room.comment.title }}</h2>
                <p>"{{ room.comment.comment }}"</p>
                <h4>{{ room.comment.name }}</h4>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useRoomStore } from '@/stores/room';
    const room = computed(() => useRoomStore().roomDetail);
</script>

<style scoped>
    section{
        position: relative;
        padding: 2.5rem 10% 2.5rem 10%;
        overflow: hidden;
        background: var(--navy);
    }
    .section-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .si-picture{
        width: 50%;
    }
    .si-picture-img{
        width: 100%;
    }
    .si-picture-img img{
        width: 100%;
    }
    .si-text{
        width: 50%;
        padding: 0 1rem 0 2rem;
        color: var(--white);
    }
    .si-text h3{
        font-size: 1.1rem;
        color: var(--primary);
        margin: 0;
    }
    .si-text h2{
        font-family: poppins;
        font-size: 1.5rem;
    }
    .si-text p{
        font-size: 1rem;
        margin: 0;
    }
    .si-text h4{
        font-size: 1rem;
        margin: 1rem 0 1rem 0;
    }
    .cov-strip{
        display: flex;
        justify-content: left;
        padding: 0 0 0 0;
        transition: 0.2s;
        margin: 0 0 1rem 0;
    }
    .strip{
        width: 5%;
        height: 0.5rem;
        background: var(--white);
        transition: 0.2s;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .si-picture{
            width: 100%;
        }
        .si-text{
            width: 100%;
            padding: 2.5rem 1rem 0 0;
        }
    }
</style>