<template>
    <ContactTopComponent/>
    <ContactFormComponent/>
    <ContactMapsComponent/>
</template>

<script setup>
    import ContactTopComponent from '@/components/contact/ContactTopComponent.vue';
    import ContactFormComponent from '@/components/contact/ContactFormComponent.vue';
    import ContactMapsComponent from '@/components/contact/ContactMapsComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'RSIA Melinda Bandung - Hubungi Kami',
            description: 'Dengan senang hati resepsionis kami yang profesional akan membantu serta memberikan informasi terkait informasi dan masalah yang ingin anda tanyakan.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>