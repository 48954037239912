import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import axiosMain from "../axiosmain.js";
import { showNotification, createSlug } from "@/functions/general.js";
import { specializations } from "@/data/specializations.js";
import { docsort } from "@/data/docsort.js";
import { organizeSchedule } from "@/functions/doctor.js";
// import { searchbarSelect as sbSelect } from "@/functions/doctor.js";

export const useDoctorStore = defineStore("doctor", {
    state: () => ({
        // ==================== states used on doctor page and its relation
        // rawData: null,
        rawDoctors: null,
        allDoctors: null,
        searchbarSelectSpecialist: {id: null, input: {value: ''}},
        searchBar: {select: {title: 'Semua Dokter', value: 'all'}, input: {value: null}},
        specialists: null,
        filteredSpecialist: null,
        specialistList: null,
        header: null,
        poliSelect: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on doctor page and its relation
        // async setData(){
        //     if(!this.rawData){
        //         try{
        //             const spclz = specializations;
        //             var i;
        //             var j;
        //             var k;
        //             const params = {
        //                 facility_id: 'a78bd22e-e60d-4d5a-ad8e-487adb61d25f',
        //                 withImages: true
        //             };
        //             const response = await axiosMain.get(`doctor/options`, {params: params});
        //             for(i = 0; i < response.data.data.length; i++){
        //                 for(j = 0; j < response.data.data[i].specializations.length; j++){
        //                     for(k = 0; k < spclz.length; k++){
        //                         if(spclz[k].id == response.data.data[i].specializations[j].id){
        //                             spclz[k].doctors.push({
        //                                 name: response.data.data[i].user.name,
        //                                 image_url: response.data.data[i].profile_image?.path_url || ''
        //                             });
        //                         }
        //                     }
        //                 }
        //             }
        //             console.log(response);
        //             console.log(spclz);
        //         }catch(error){
        //             console.log(error);
        //         }
        //     }
        // },
        async setSpecialists(){
            if(!this.rawDoctors){
                try{
                    const spclz = specializations;
                    var i;
                    var j;
                    var k;
                    var l;
                    const params = {
                        facility_id: 'a78bd22e-e60d-4d5a-ad8e-487adb61d25f',
                        withImages: true
                    };
                    const rsmel = await axiosMain.get(`doctor/options`, {params: params});
                    for(i = 0; i < rsmel.data.data.length; i++){
                        var thspec = Object.values(rsmel.data.data[i].specializations.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {}));
                        for(j = 0; j < thspec.length; j++){
                            for(k = 0; k < spclz.length; k++){
                                spclz[k].slug = createSlug(spclz[k].name);
                                if(spclz[k].id == thspec[j].id){
                                    var sortnum = 10;
                                    for(l = 0; l < docsort.length; l++){
                                        // console.log(docsort[l].name);
                                        // console.log(rsmel.data.data[i].name);
                                        if(docsort[l].name == rsmel.data.data[i].user.name){
                                            sortnum = docsort[l].value;
                                            // console.log(docsort[l].value);
                                        }
                                    }
                                    spclz[k].doctors.push({
                                        name: rsmel.data.data[i].user.name,
                                        slug: rsmel.data.data[i].slug,
                                        image_url: rsmel.data.data[i].profile_image?.path_url || false,
                                        sort: sortnum
                                    });
                                }
                            }
                        }
                    }
                    for(i = 0; i < spclz.length; i++){
                        spclz[i].doctors.sort((a, b) => b.sort - a.sort);
                        // spclz[i].doctors.sort((a, b) => {
                        //     if (a.name < b.name) return -1;
                        //     if (a.name > b.name) return 1;
                        //     return 0;
                        // });
                    }
                    // console.log(rsmel);
                    // var splist = [{name: 'Dokter Spesialis Obstetri & Ginekologi (Kandungan)', slug: 'dokter-spesialis-obstetri-ginekologi-kandungan'}, {name: 'Dokter Umum IGD', slug: 'dokter-umum-igd'}];
                    const response = await axiosClient.get(`doctor`);
                    const header = await axiosClient.get(`page-header/doctor`);
                    // const docspec = await axiosClient.get(`doctor/specialist`);
                    this.rawDoctors = response.data.doctors;
                    // var sp = [];
                    // var ct = [];
                    // for(i = 0; i < this.rawDoctors.length; i++){
                    //     if(this.rawDoctors[i].dataDoctor.length > 0){
                    //         sp.push({name: this.rawDoctors[i].specialist, slug: createSlug(this.rawDoctors[i].specialist), doctors: this.rawDoctors[i].dataDoctor});
                    //         ct.push({name: this.rawDoctors[i].specialist, slug: createSlug(this.rawDoctors[i].specialist), id: this.rawDoctors[i].dataDoctor[0].doctor_speciality_id});
                    //     }
                    // }
                    // for(i = 0; i < sp.length; i++){
                    //     for(j = 0; j < sp[i].doctors.length; j++){
                    //         sp[i].doctors[j].days = null;
                    //     }
                    // }
                    this.allDoctors = spclz;
                    this.specialists = spclz;
                    this.filteredSpecialist = spclz;
                    this.specialistList = spclz;
                    this.header = header.data.page_header;
                    this.setPoliSelect();
                    // this.specialistList = docspec.data.data;
                    // console.log(this.rawDoctors);
                    // console.log(this.specialists);
                    // console.log(this.specialistList);
                }catch(error){
                    console.log(error);
                }
            }
        },
        async setDoctorDetail(slug){
            console.log(this.specialists);
            try{
                for(var i = 0; i < this.allDoctors.length; i++){
                    for(var j = 0; j < this.allDoctors[i].doctors.length; j++){
                        if(this.allDoctors[i].doctors[j].slug == slug){
                            if(!this.allDoctors[i].doctors[j].days){
                                try{
                                    const facilityid = 'a78bd22e-e60d-4d5a-ad8e-487adb61d25f';
                                    const response = await axiosMain.get(`doctor/` + slug);
                                    for(var k = 0; k < response.data.data.practices.length; k++){
                                        if(response.data.data.practices[k].facility.id == facilityid && response.data.data.practices[k].specialization.id == this.allDoctors[i].id){
                                            this.allDoctors[i].doctors[j].days = organizeSchedule(response.data.data.practices[k].schedules);
                                            this.allDoctors[i].doctors[j].awards = response.data.data.awards;
                                            this.allDoctors[i].doctors[j].organizations = response.data.data.organization;
                                            this.allDoctors[i].doctors[j].publications = response.data.data.publication;
                                        }
                                    }
                                    console.log(response);
                                }catch(error){
                                    console.log(error);
                                }
                            }
                        }
                    }
                }
                console.log(this.specialists);
            }catch(error){
                console.log(error);
            }
            // for(var i = 0; i < this.allDoctors.length; i++){
            //     for(var j = 0; j < this.allDoctors[i].doctors.length; j++){
            //         if(this.allDoctors[i].doctors[j].doctor_id == id){
            //             if(!this.allDoctors[i].doctors[j].days){
            //                 try{
            //                     const response = await axiosClient.get(`doctor/` + id);
            //                     this.allDoctors[i].doctors[j].days = response.data.doctor.doctordays;
            //                     this.allDoctors[i].doctors[j].about = response.data.doctor.about;
            //                     this.allDoctors[i].doctors[j].awards = response.data.doctor.awards;
            //                     this.allDoctors[i].doctors[j].organizations = response.data.doctor.organitions;
            //                     this.allDoctors[i].doctors[j].publications = response.data.doctor.publication;
            //                     // console.log(this.allDoctors[i].doctors[j].days);
            //                 }catch(error){
            //                     console.log(error);
            //                 }
            //             }
            //         }
            //     }
            // }
        },
        setPoliSelect(){
            this.poliSelect = {specialists: this.specialistList, selected: null, form: null};
            // console.log(this.poliSelect);
        },
        selectPoli(slug){
            this.poliSelect.doctors = null;
            this.poliSelect.schedule = null;
            for(var i = 0; i < this.allDoctors.length; i++){
                if(this.allDoctors[i].slug == slug){
                    this.poliSelect.selected = this.allDoctors[i];
                    this.poliSelect.doctors = {selected: null, list: this.allDoctors[i].doctors};
                    // console.log(this.allDoctors[i]);
                }
            }
            // console.log(slug);
            console.log(this.poliSelect);
        },
        async selectPoliDoctor(doctor){
            this.poliSelect.doctors.selected = doctor;
            try{
                const response = await axiosClient.get(`doctor/` + this.poliSelect.doctors.selected.doctor_id);
                this.poliSelect.schedule = response.data.doctor.doctordays;
                console.log(response);
            }catch(error){
                console.log(error);
            }
            console.log(this.poliSelect);
        },
        selectPoliSchedule(schedule){
            this.poliSelect.schedule.selected = schedule;
            console.log(this.poliSelect);
            console.log(schedule);
        },
        submitPoliRegistration(){
            const name = document.getElementById('poli-input-name');
            const phone = document.getElementById('poli-input-phone');
            if(this.poliSelect.schedule?.selected){
                if(name.value){
                    if(phone.value){
                        var text = 'Halo, saya ingin mendaftar atas nama ' + name.value + ' untuk berkonsultasi dengan dokter ' + this.poliSelect.doctors.selected.name + ' di poli ' + this.poliSelect.selected.name + ' pada ' + this.poliSelect.schedule.selected.text + ' jika tidak bisa pada waktu tersebut bisa diinfokan untuk waktu yang lain?';
                        window.open(`https://wa.me/6285223388888?text=` + text.replace('&', 'dan'), `blank`);
                        console.log(this.poliSelect);
                    }else{
                        showNotification('', 'Nomor Ponsel tidak boleh kosong', 2000, 'var(--primary)');
                    }
                }else{
                    showNotification('', 'Nama Pasien tidak boleh kosong', 2000, 'var(--primary)');
                }
            }else{
                showNotification('', 'Pilih Jam Praktek dahulu !', 2000, 'var(--primary)');
            }
        },
        // async selectPoliSchedule(){
        //     try{
        //         const response = await axiosClient.get(`doctor/` + this.poliSelect.doctors.selected.doctor_id);
        //         console.log(response);
        //     }catch(error){
        //         console.log(error);
        //     }
        // },
        inputSelectSpecialist(value){
            this.searchbarSelectSpecialist.input.value = value;
            if(this.specialists && this.allDoctors){
                if(value.length){
                    this.searchBar.select.title = 'filter: "' + value + '"';
                    var newsd = [];
                    newsd = this.allDoctors;
                    var result = [];
                    var inval = new RegExp(value, 'gi');
                    for(var i = 0; i < newsd.length; i++){
                        if(newsd[i].name.match(inval)){
                            result.push(newsd[i]);
                        }
                    }
                    this.filteredSpecialist = result;
                    // this.specialists = result;
                }else{
                    this.searchBar.select.title = 'Semua Dokter';
                    // this.searchbarSelect('Semua Dokter', 'all');
                    this.filteredSpecialist = this.allDoctors;
                    // this.specialists = this.allDoctors;
                }
                this.searchDoctor();
            }
            // console.log(this.searchbarSelectSpecialist);
            // console.log(this.filteredSpecialist);
        },
        searchbarSelect(specialist, slug){
            console.log(slug);
            this.searchBar.select.title = specialist;
            this.searchBar.select.value = slug;
            this.searchDoctor();
        },
        searchbarInput(value){
            this.searchBar.input.value = value;
            this.searchDoctor();
        },
        searchDoctor(){
            var newsd = [];
            if(this.searchBar.select.value != 'all'){ // if selected specialist
                for(var i = 0; i < this.filteredSpecialist.length; i++){
                    if(this.filteredSpecialist[i].slug == this.searchBar.select.value){
                        newsd.push(this.filteredSpecialist[i]);
                    }
                }
            }else{
                newsd = this.filteredSpecialist;
            }
            if(this.searchBar.input.value){
                var result = [];
                var inval = new RegExp(this.searchBar.input.value, 'gi');
                for(i = 0; i < newsd.length; i++){
                    var thisdocs = [];
                    for(var j = 0; j < newsd[i].doctors.length; j++){
                        if(newsd[i].doctors[j].name.match(inval)){
                            thisdocs.push(newsd[i].doctors[j]);
                        }
                    }
                    if(thisdocs.length > 0){
                        result.push({name: newsd[i].name, slug: newsd[i].slug, doctors: thisdocs});
                    }
                }
                this.specialists = result;
                // this.filteredSpecialist = this.allDoctors;
            }else{
                this.specialists = newsd;
                // this.filteredSpecialist = this.allDoctors;
            }
        }
    }
});