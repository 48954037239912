export const servicesLayout = [
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 2,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 3,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 4,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 5,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 6,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 7,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 8,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 9,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 10,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 11,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 12,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 13,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 14,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 15,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 16,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 17,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 18,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 19,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 20,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 21,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 22,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 23,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 24,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 25,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 26,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 27,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 28,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 29,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 30,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 31,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 32,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 33,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 34,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 35,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 36,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 37,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 38,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 39,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 40,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 41,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 42,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 43,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 44,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 45,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 46,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 47,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 48,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 49,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
];
export const galleryLayout = [
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
    {
        id: 0,
        sectionClasses: 'section-row',
        linkColor: 'link-navy',
        strip: 'navy',
        style: {
            background: 'section-bg-white',
            color: 'section-color-navy',
            flexDirection: 'row'
        }
    },
    {
        id: 1,
        sectionClasses: 'section-row-reverse',
        linkColor: 'link-white',
        strip: 'white',
        style: {
            background: 'section-bg-navy',
            color: 'section-color-white',
            flexDirection: 'row'
        }
    },
];