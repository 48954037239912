<template>
    <section v-if="vacancy">
        <div class="section-head">
            <div class="sh-top">
                <h2>{{ vacancy.department.name }}</h2>
                <h1>{{ vacancy.name }}</h1>
                <div class="sh-locdate">
                    <!-- <p class="sh-location">Bandung, Indonesia (Remote)</p> -->
                    <p class="sh-date">Date created: {{ dateFormatIndonesia(vacancy.created_at) }} - Last updated: {{ dateFormatIndonesia(vacancy.updated_at) }}</p>
                </div>
            </div>
            <div class="sh-bottom">
                <div class="sh-bottom-text">
                    <div class="sbt-part">
                        <div class="sbt-column">
                            <div class="sbt-column-param">
                                <span>Function :</span>
                            </div>
                            <div class="sbt-column-value">
                                <div v-html="vacancy.function"></div>
                                <!-- <span>Enterprise Software & Systems</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="sbt-part">
                        <div class="sbt-column">
                            <div class="sbt-column-param">
                                <span>Type :</span>
                            </div>
                            <div class="sbt-column-value">
                                <div v-html="vacancy.type"></div>
                                <!-- <span>Full-time</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="sbt-part">
                        <div class="sbt-column">
                            <div class="sbt-column-param">
                                <span>Experience :</span>
                            </div>
                            <div class="sbt-column-value">
                                <div v-html="vacancy.experience"></div>
                                <!-- <span>1 to 4 years</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="sbt-part">
                        <div class="sbt-column">
                            <div class="sbt-column-param">
                                <span>Vacancies :</span>
                            </div>
                            <div class="sbt-column-value">
                                <div v-html="vacancy.vacancies"></div>
                                <!-- <span>2 Vacancies</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sh-bottom-button">
                    <!-- <div class="sbb-button">
                        <div class="sbb-button-text">
                            <span>Apply Job</span>
                        </div>
                        <div class="sbb-button-icon">
                            <img src="../../assets/images/icons/arrow-2.png" alt="rs melinda 3"/>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="section-body">
            <div class="sb-left">
                <div class="sl-content">
                    <h2>Job Description</h2>
                    <div class="sl-content-description" v-html="vacancy.job_description"></div>
                    <div class="sl-content-description" v-html="vacancy.job_requirements"></div>
                    <div class="sl-content-description" v-html="vacancy.job_functions"></div>
                </div>
                <!-- <div class="sl-skills" v-if="vacancy.required_skills">
                    <h2>Required Skills</h2>
                    <div class="sl-skills-list">
                        <div class="ssl-part">
                            <span>Backend Development</span>
                        </div>
                        <div class="ssl-part">
                            <span>Javascript</span>
                        </div>
                        <div class="ssl-part">
                            <span>REST APIs</span>
                        </div>
                        <div class="ssl-part">
                            <span>Golang</span>
                        </div>
                        <div class="ssl-part">
                            <span>Express.js</span>
                        </div>
                        <div class="ssl-part">
                            <span>Node.js</span>
                        </div>
                        <div class="ssl-part">
                            <span>Javascript Framework</span>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="sb-right">
                <div class="sr-jobs">
                    <h2>Looking for other available position ?</h2>
                    <p>Melinda Hospital Jobs</p>
                    <div class="sr-jobs-link">
                        <router-link to="/careers">
                            <span>Browse Others Jobs</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { computed } from '@vue/reactivity';
    import { useCareerStore } from '@/stores/career';
    import { dateFormatIndonesia } from '@/functions/general';
    const vacancy = computed(() => useCareerStore().vacancyDetail);
    useCareerStore().setVacancyDetail(useRoute().params.slug);
</script>

<style scoped>
    section{
        color: var(--grey);
    }
    .section-head{
        padding: 2.5rem 10% 2.5rem 10%;
        width: 100%;
        /* background: var(--navy); */
        transition: 0.2s;
    }
    .sh-top h2{
        font-size: 1.125rem;
        margin: 0;
        color: var(--primary);
    }
    .sh-top h1{
        font-size: 3.25rem;
        margin: 1rem 0 2rem 0;
        transition: 0.2s;
    }
    .sh-location{
        margin: 1rem 0 1rem 0;
    }
    .sh-date{
        margin: 0;
    }
    .sh-bottom{
        display: flex;
        flex-wrap: wrap;
        padding: 2rem 0 0 0;
    }
    .sh-bottom-text{
        display: flex;
        flex-wrap: wrap;
        width: 85%;
        padding: 0 1rem 0 0;
        transition: 0.2s;
    }
    .sbt-part{
        width: 25%;
        padding: 0 1rem 1rem 0;
        transition: 0.2s;
    }
    .sbt-column-param{
        color: var(--primary);
        padding: 0 0 0.5rem 0;
    }
    .sh-bottom-button{
        width: 15%;
        display: flex;
        justify-content: right;
        transition: 0.2s;
    }
    .sbb-button{
        width: 100%;
        max-width: 200px;
        max-height: 50px;
        display: flex;
        background: var(--primary);
        color: var(--white);
        padding: 1rem 2rem 1rem 2rem;
        border-radius: 10rem;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
    }
    .sbb-button-text{
        width: 90%;
    }
    .sbb-button-icon{
        width: 10%;
    }
    .section-body{
        display: flex;
        flex-wrap: wrap;
        padding: 2.5rem 10% 2.5rem 10%;
        color: var(--grey);
        transition: 0.2s;
    }
    .sb-left{
        width: 70%;
        transition: 0.2s;
    }
    .sl-content h2{
        font-family: poppins;
        font-size: 1.125rem;
        color: var(--navy);
        margin: 0;
    }
    .sl-skills h2{
        font-family: poppins;
        font-size: 1.125rem;
        color: var(--navy);
        margin: 0;
    }
    .sl-skills-list{
        display: flex;
        flex-wrap: wrap;
        padding: 1.5rem 0 0 0;
    }
    .ssl-part{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 5rem;
        margin: 0 0.5rem 1rem 0;
        white-space: nowrap;
    }
    .sb-right{
        width: 30%;
        padding: 0 0 1rem 1rem;
        transition: 0.2s;
    }
    .sr-jobs{
        width: 100%;
        background: var(--navy);
        color: var(--white);
        padding: 1rem;
    }
    .sr-jobs h2{
        font-size: 1rem;
        margin: 0;
    }
    .sr-jobs-link{
        padding: 1rem 0 1rem 0;
    }
    .sr-jobs-link a{
        text-decoration: none;
        color: var(--white);
        background: var(--primary);
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 5rem;
        white-space: nowrap;
        font-size: 0.9rem;
    }
    .section-content h2{
        color: var(--navy);
    }
    @media only screen and (max-width: 1500px){
        .section-head{
            padding: 2.5rem 5% 2.5rem 5%;
        }
        .section-body{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1200px){
        .sh-bottom-text{
            width: 100%;
            margin: 0 0 1rem 0;
        }
        .sh-bottom-button{
            width: 100%;
            justify-content: left;
        }
    }
    @media only screen and (max-width: 750px){
        .section-head{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .sh-top h1{
            font-size: 2rem;
        }
        .sbt-part{
            width: 50%;
        }
        .section-body{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .sb-left{
            width: 100%;
        }
        .sb-right{
            width: 100%;
            padding: 0 0 0 0;
        }
    }
    @media only screen and (max-width: 370px){
        .sbt-part{
            width: 100%;
        }
    }
    /*  */
</style>