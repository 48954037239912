<template>
    <GalleryTopComponent/>
    <GalleryComponent/>
</template>

<script setup>
    import GalleryTopComponent from '@/components/gallery/GalleryTopComponent.vue';
    import GalleryComponent from '@/components/gallery/GalleryComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        setPageData({
            title: 'Galeri RSIA Melinda Bandung',
            description: 'RSIA Melinda Bandung ini memiliki Desain yang sangat menarik dengan konsep Hotel. Tentunya menjadikan suasana yang nyaman bagi para pasien yang ingin berkunjung ke Rumah sakit ini.'
        });
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>