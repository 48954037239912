<template>
    <div class="st-image-top">
        <div class="hdf-image" id="element-carousel-room" v-if="room">
            <div class="hdf-controls">
                <div class="control-button control-button-left">
                    <span>{{ '<' }}</span>
                </div>
                <div class="control-button control-button-right">
                    <span>{{ '>' }}</span>
                </div>
                <div class="fullscreen-button" @click="fullScreen('element-carousel-room');">[fullscreen]</div>
            </div>
            <div class="hh-carousel carousel hhc-smooth" id="carousel-room">
                <div class="hc-part" v-for="(image, index) in room.images" :key="image">
                    <div class="hcp-inner">
                        <div class="img-cover"></div>
                        <img :src="image.img_url" alt="rsia melinda"/>
                    </div>
                    <div class="hcp-description">
                        <!-- <h2>{{ image.name }}</h2> -->
                        <p>{{ (index + 1) + '/' + room.images.length }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="st-image-bottom" v-if="room.images.length > 4">
        <div class="sib-left">
            <template v-for="(button, index) in room.images" :key="button">
                <div class="sib-left-part" v-if="index <= 2">
                    <img :src="button.img_url" alt="rsia melinda" @click="fullScreenImage('element-carousel-room', 'carousel-room', index);"/>
                </div>
            </template>
        </div>
        <div class="sib-right" @click="fullScreen('element-carousel-room')">
            <p>{{ '+' + (room.images.length - 3) + ' foto lagi' }}</p>
        </div>
    </div>
    <div class="st-image-bottom" v-else>
        <div class="sib-left sib-left-fullwidth" v-if="room.images.length == 4">
            <template v-for="(button, index) in room.images" :key="button">
                <div class="sib-left-part" v-if="index <= 2">
                    <img :src="button.img_url" alt="rsia melinda" @click="fullScreenImage('element-carousel-room', 'carousel-room', index);"/>
                </div>
                <div class="sib-left-part slp-last" v-else>
                    <img :src="button.img_url" alt="rsia melinda" @click="fullScreenImage('element-carousel-room', 'carousel-room', index);"/>
                </div>
            </template>
        </div>
        <div class="sib-left" v-else>
            <template v-for="(button, index) in room.images" :key="button">
                <div class="sib-left-part">
                    <img :src="button.img_url" alt="rsia melinda" @click="fullScreenImage('element-carousel-room', 'carousel-room', index);"/>
                </div>
                <!-- <div class="sib-left-part slp-last" v-else>
                    <img :src="button.img_url" alt="rsia melinda" @click="fullScreenImage('element-carousel-room', 'carousel-room', index);"/>
                </div> -->
            </template>
        </div>
    </div>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useRoomStore } from "../../stores/room";
    import { onMounted } from 'vue';
    const room = computed(() => useRoomStore().roomDetail);
    function fullScreen(id){
        const el = document.getElementById(id);
        const slider = document.querySelector('.carousel');
        const fsbtn = document.querySelector('.fullscreen-button');
        if(!document.fullscreenElement){
            if(el.requestFullscreen){
                el.requestFullscreen();
            }else if(el.webkitRequestFullscreen){ /* Safari */
                el.webkitRequestFullscreen();
            }else if(el.msRequestFullscreen){ /* IE11 */
                el.msRequestFullscreen();
            }
            fsbtn.innerHTML = '[exit]';
        }else{
            if(document.exitFullscreen){
                document.exitFullscreen();
            }
            fsbtn.innerHTML = '[fullscreen]';
        }
        setTimeout(function(){
            if(slider.scrollLeft > 0){
                slider.scrollLeft = 0;
            }
        }, 270);
    }
    function fullScreenImage(el, ca, index){
        fullScreen(el);
        setTimeout(function(){
            sliderScrollIndex(ca, index);
        }, 500);
    }
    function sliderScrollIndex(slider, index){
        const sl = document.getElementById(slider);
        if(index > 0){
            sl.scrollLeft = sl.offsetWidth * index;
        }else{
            sl.scrollLeft = 0;
        }
    }
    onMounted(() => {
        let isDown = false;
        let startX;
        let scrollLeft;
        const slider = document.querySelector('.carousel');
        const btleft = document.querySelector('.control-button-left');
        const btright = document.querySelector('.control-button-right');
        // const schildren = slider.children;
        const sparent = slider.parentElement;
        const end = () => {
            isDown = false;
            slider.classList.add('hhc-smooth');
            slider.scrollLeft = Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth;
        }
        const start = (e) => {
            isDown = true;
            slider.classList.remove('hhc-smooth');
            startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        }
        const move = (e) => {
            if(!isDown) return;
            // e.preventDefault();
            const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            const dist = (x - startX) * 1.9;
            slider.scrollLeft = scrollLeft - dist;
        }
        const goLeft = (e) => {
            console.log('Left');
            e.preventDefault();
            // goTop();
            slider.scrollLeft = (Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth) - sparent.scrollWidth;
        }
        const goRight = (e) => {
            console.log('Right');
            e.preventDefault();
            // goTop();
            slider.scrollLeft = (Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth) + sparent.scrollWidth;
        }
        const sresize = () => {
            slider.scrollLeft = 0;
        }
        // const goTop = () => {
        //     document.body.scrollTop = 0;
        //     document.documentElement.scrollTop = 0;
        // }
        (() => {
            slider.addEventListener('mousedown', start);
            slider.addEventListener('touchstart', start);
            slider.addEventListener('mousemove', move);
            slider.addEventListener('touchmove', move);
            slider.addEventListener('mouseleave', end);
            slider.addEventListener('mouseup', end);
            slider.addEventListener('touchend', end);
            slider.addEventListener('resize', sresize);
            btleft.addEventListener('click', goLeft);
            btright.addEventListener('click', goRight);
        })();
        // if(slider){
        // }
    });
</script>

<style scoped>
    .st-image-top{
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 0 0;
        overflow: hidden;
        background: #000000;
    }
    .hdf-image{
        position: relative;
        width: 100%;
        display: flex;
        height: 27rem;
        overflow: hidden;
        user-select: none;
        background: rgb(0, 0, 0);
        /* border: 1px solid green; */
    }
    .control-button{
        position: absolute;
        font-size: 1rem;
        color: var(--white);
        background: #ffffff6e;
        z-index: 5;
        width: 25px;
        height: 25px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .control-button-left{
        top: 45%;
        left: 10px;
    }
    .control-button-right{
        top: 45%;
        right: 10px;
    }
    .hh-carousel{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        transition: 0.2s;
    }
    .hhc-smooth{
        scroll-behavior: smooth;
    }
    .hc-part{
        position: relative;
        display: inline-block;
        min-width: 100%;
        width: 100%;
        height: 100%;
        /* border: 1px solid green; */
    }
    .fullscreen-button{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 5;
        cursor: pointer;
        color: rgba(211, 211, 211, 0.534);
        padding: 1rem;
    }
    .hcp-inner{
        position: relative;
        display: flex;
        height: 100%;
        /* width: 100%; */
        overflow: hidden;
        justify-content: center;
        align-items: center;
        /* border: 1px solid green; */
    }
    .hcp-inner img{
        /* width: 100%; */
        /* max-width: 100%; */
        /* height: 100%; */
        max-height: 100%;
        /* border: 1px solid green; */
    }
    .img-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .hcp-description{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.432);
        color: var(--white);
        padding-left: 5%;
        padding-right: 5%;
        /* border: 1px solid green; */
    }
    .st-image-bottom{
        display: flex;
        width: 100%;
        height: 20%;
        padding: 0.5rem 0 0.5rem 0;
    }
    .sib-left{
        width: 75%;
        height: 100%;
        display: flex;
    }
    .sib-left-fullwidth{
        width: 100%;
    }
    .sib-left-part{
        width: 33.33%;
        height: 100%;
        overflow: hidden;
        padding: 0 0.5rem 0 0;
        cursor: pointer;
        user-select: none;
        /* border: 1px solid green; */
    }
    .sib-left-part img{
        width: 100%;
        transition: 0.2s;
    }
    .slp-last{
        padding: 0;
    }
    .sib-right{
        width: 25%;
        height: 100%;
        border: 1px solid var(--softgrey);
        background: #00000050;
        color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        user-select: none;
        font-size: 0.75rem;
    }
    @media only screen and (max-width: 1700px){
        .st-image-top{
            height: 85%;
        }
        .st-image-bottom{
            height: 15%;
        }
    }
    @media only screen and (max-width: 1500px){
        .st-image-top{
            height: 85%;
        }
        .hdf-image{
            height: 32rem;
        }
        .st-image-bottom{
            height: 15%;
        }
    }
    @media only screen and (max-width: 1050px){
        /* .st-image-top{
            height: 80%;
        } */
        .hdf-image{
            height: 26rem;
        }
        /* .st-image-bottom{
            height: 20%;
        } */
        .hh-left{
            padding-top: 2rem;
            padding-right: 2rem;
        }
        .hh-left h1{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 1000px){
        .st-image-top{
            height: 80%;
        }
        .st-image-bottom{
            height: 20%;
        }
    }
    @media only screen and (max-width: 750px){
        .st-image-top{
            height: 85%;
        }
        .hdf-image{
            /* height: auto; */
        }
        .st-image-bottom{
            height: 15%;
        }
        .hcp-inner img{
            width: 100%;
            /* max-height: 200px; */
        }
    }
    @media only screen and (max-width: 490px){
        .sib-left-part{
            border-right: 5px solid var(--white);
        }
        .sib-left-part img{
            width: auto;
            height: 100%;
        }
    }
</style>