<template>
    <NotFoundComponent/>
</template>

<script setup>
    import NotFoundComponent from '@/components/exceptions/NotFoundComponent.vue';
    import { setPageData } from '@/functions/general';
    import { onMounted, onUnmounted } from 'vue';
    onMounted(() => {
        const metaTag = document.createElement('meta');
        metaTag.setAttribute('name', 'robots');
        metaTag.content = 'noindex';
        document.head.appendChild(metaTag);
        // document.querySelector('meta[name="robots"]').setAttribute("content", "noindex");
        setPageData({
            title: 'Halaman Tidak Ditemukan',
            description: 'RSIA Melinda Bandung didirikan pada tahun 2004 oleh dr, Susan Melinda, Sp.OG. Kami memahami Ibu dan anak agar mendapatkan pelayanan kesehatan yang baik dengan penuh kasih sayang.'
        });
    });
    onUnmounted(() => {
        if(document.querySelector('meta[name="robots"]')){
            document.querySelector('meta[name="robots"]').remove();
        }
    });
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>






