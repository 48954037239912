<template>
    <section>
        <div class="section-inner">
            <div class="si-header">
                <h2>RSIA Melinda</h2>
                <h1>Kesempatan Karir di RSIA Melinda Bandung</h1>
                <p>Tenaga kerja yang kompeten, berdedikasi dan bersemangat tinggi untuk kontribusi melayani kesehatan masyarakat merupakan salah satu hal yang kami banggakan.</p>
            </div>
            <div class="si-box">
                <div class="si-search" v-if="departments">
                    <div class="si-search-select">
                        <div class="sss-button" id="sss-button-career" @click="topSearchSelect('sss-button-career');">
                            <div class="button-cover float-button"></div>
                            <div class="sss-text">
                                <div class="sss-text-title">Role</div>
                                <div class="sss-text-button">{{ searchBar.select.title }}</div>
                            </div>
                            <div class="sss-icon">
                                <div class="sss-icon-inner float-arrow">
                                    <img src="../../assets/images/icons/arrow-1.png" alt="rs melinda 3"/>
                                </div>
                            </div>
                        </div>
                        <div class="sss-option float">
                            <div class="sss-option-button" @click="useCareerStore().searchbarSelect('Semua', 'all');">
                                <span>Semua</span>
                            </div>
                            <div class="sss-option-button" @click="useCareerStore().searchbarSelect(department.name, department.slug);" v-for="department in departments" :key="department">
                                <span>{{ department.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="si-search-input">
                        <input type="text" id="sss-input-career" :value="searchBar.input.value" placeholder="keywords" autocomplete="off" @input="searchbarInput('sss-input-career');" />
                    </div>
                    <div class="si-search-button">
                        <span>Search Jobs</span>
                    </div>
                </div>
                <!-- <TopSearchOptionSkeleton v-else/> -->
            </div>
            <div class="career-box" v-if="vacancies">
                <div class="career-box-part" v-for="vacancy in vacancies" :key="vacancy">
                    <router-link :to="'/careers/detail/' + vacancy.slug">
                        <div class="cbp-inner">
                            <div class="cb-date">
                                <span>{{ dateFormatIndonesia(vacancy.created_at) }}</span>
                            </div>
                            <div class="cbp-detail">
                                <h2>{{ vacancy.name }}</h2>
                                <h3>{{ vacancy.department.name }}</h3>
                                <p>{{ vacancy.footer }}</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- <CareerSkeleton v-else/> -->
            <template v-if="vacancies">
                <div class="career-more" v-if="vacancies.length > 12">
                    <button>Load more jobs</button>
                </div>
            </template>
        </div>
    </section>
</template>

<script setup>
    // import TopSearchOptionSkeleton from '@/components/skeletons/parts/TopSearchOptionSkeleton.vue';
    // import CareerSkeleton from '@/components/skeletons/sections/CareerSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useCareerStore } from '@/stores/career';
    import { topSearchSelect, dateFormatIndonesia } from '@/functions/general';
    import { searchbarInput } from '@/functions/career';
    const searchBar = computed(() => useCareerStore().searchBar);
    const departments = computed(() => useCareerStore().departments);
    const vacancies = computed(() => useCareerStore().vacanciesShow);
    useCareerStore().setVacancies();
</script>

<style scoped>
    section{
        transition: 0.2s;
        padding: 2.5rem 9% 2.5rem 9%;
    }
    .section-inner{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .si-header{
        width: 100%;
        text-align: center;
        padding: 0 0 2.5rem 0;
        color: var(--grey);
    }
    .si-header h2{
        margin: 0;
        font-size: 1.125rem;
        color: var(--primary);
        transition: 0.2s;
    }
    .si-header h1{
        font-family: poppins;
        font-size: 2rem;
        margin: 0;
        transition: 0.2s;
        color: var(--navy);
    }
    .si-header p{
        margin: 0;
    }
    .si-box{
        transition: 0.2s;
        padding: 0 0.5% 0 0.5%;
    }
    .si-search{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 75px;
        transition: 0.2s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
    }
    .si-search-select{
        position: relative;
        width: 30%;
        height: 100%;
        user-select: none;
        background: var(--white);
        transition: 0.2s;
    }
    .sss-button{
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
    }
    .button-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .sss-text{
        width: 90%;
    }
    .sss-text-title{
        font-weight: 700;
        padding: 0 0.5rem 0 0.5rem;
    }
    .sss-text-button{
        padding: 0.5rem;
        font-size: 0.9rem;
    }
    .sss-icon{
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sss-icon-inner{
        text-align: center;
        width: 20px;
        height: 20px;
    }
    .sss-icon img{
        max-width: 100%;
        transition: 0.2s;
    }
    .sss-option{
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        background: var(--white);
        display: none;
        -webkit-animation-name: animatesssoption;
        -webkit-animation-duration: 0.4s;
        animation-name: animatesssoption;
        animation-duration: 0.4s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
        z-index: 1;
    }
    @-webkit-keyframes animatesssoption {
        from {top:150%; opacity:0}
        to {top:110%; opacity:1}
    }
    @keyframes animatesssoption {
        from {top:150%; opacity:0}
        to {top:110%; opacity:1}
    }
    .float-active{
        display: block;
    }
    .sss-option-button{
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
    .sss-option-button:hover{
        background: var(--smoke);
    }
    .si-search-input{
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid var(--softgrey);
        padding: 1rem;
        background: var(--white);
        transition: 0.2s;
    }
    .si-search-input input{
        border: none;
        outline: none;
        width: 100%;
        height: 50px;
        color: var(--grey);
        font-size: 1.2rem;
    }
    .si-search-button{
        width: 30%;
        height: 100%;
        background: var(--primary);
        color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
    }
    .career-box{
        display: flex;
        flex-wrap: wrap;
        padding: 2.5rem 0 0 0;
    }
    .career-box-part{
        width: 25%;
        height: 250px;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .career-box-part a{
        text-decoration: none;
        color: var(--grey);
    }
    .cbp-inner{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        background: var(--softcream);
    }
    .cbp-date{
        color: var(--grey);
    }
    .cbp-detail{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 1rem;
    }
    .cbp-detail h2{
        margin: 0;
        font-family: poppins;
        font-size: 1.2rem;
        color: var(--navy);
    }
    .cbp-detail h3{
        margin: 0 0 1rem 0;
        font-size: 1rem;
        color: var(--primary);
    }
    .cbp-detail p{
        margin: 0;
        font-size: 0.9rem;
        color: var(--grey);
    }
    .career-more{
        text-align: center;
        padding: 2.5rem 0 0 0;
    }
    .career-more button{
        background: none;
        color: var(--grey);
        border: none;
        text-decoration: underline;
        font-size: 1rem;
        font-weight: 600;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
        .si-left{
            padding: 2rem 1rem 0 5%;
        }
        .si-left h2{
            font-size: 2rem;
            margin: 0 0 2rem 0;
        }
    }
    @media only screen and (max-width: 1270px){
        .si-left{
            width: 100%;
        }
        .si-search{
            height: auto;
            box-shadow: unset;
        }
        .si-search-select{
            width: 50%;
            height: 75px;
            border-left: 1px solid var(--softgrey);
            border-bottom: 1px solid var(--softgrey);
        }
        .si-search-input{
            width: 50%;
            height: 75px;
            border-right: 1px solid var(--softgrey);
            border-bottom: 1px solid var(--softgrey);
        }
        .si-search-button{
            width: 100%;
            height: 75px;
            margin-top: 1rem;
        }
    }
    @media only screen and (max-width: 1200px){
        .career-box-part{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 1.5% 2.5rem 1.5%;
        }
        .si-header h2{
            font-size: 0.9rem;
        }
        .si-header h1{
            font-size: 2rem;
        }
        .si-left{
            padding: 2rem 1rem 0 2.5%;
        }
        .si-search-select{
            width: 100%;
            border-right: 1px solid var(--softgrey);
        }
        .si-search-input{
            width: 100%;
            margin-top: 1rem;
            border: 1px solid var(--softgrey);
        }
        .si-search-button{
            width: 100%;
        }
        .career-box-part{
            width: 50%;
        }
    }
    @media only screen and (max-width: 450px){
        .career-box-part{
            width: 100%;
        }
    }
</style>