<template>
    <section v-if="eventForm">
        <div class="section-head">
            <div class="section-head-image">
                <img :src="eventForm.image_url" alt="RSIA Melinda" />
            </div>
        </div>
        <div class="section-body">
            <div class="section-body-inner" v-if="eventForm.active">
                <div class="sbi-head">
                    <div class="sbi-head-board">
                        <div class="sbi-board-main">
                            <h1>{{ eventForm.name }}</h1>
                            <div v-html="eventForm.description"></div>
                            <!-- <p>{{ eventForm.description }}</p> -->
                        </div>
                    </div>
                </div>
                <div class="sbi-body">
                    <div class="sbi-body-inner">
                        <div v-for="input in eventForm.fields" :key="input">
                            <div class="sbi-body-board">
                                <div class="sbi-board-label" v-if="input.type != 'hidden'">
                                    <span>{{ input.label }}</span>
                                    <span class="sbl-sign" v-if="input.required">*</span>
                                </div>
                                <div class="sbi-hidden" v-if="input.type == 'hidden'">
                                    <input type="hidden" class="input-hidden required" :id="'input-' + input.name" :value="userAgent" v-if="input.required"/>
                                    <input type="hidden" class="input-hidden" :id="'input-' + input.name" :value="userAgent" v-else/>
                                </div>
                                <div class="sbi-board-input" v-if="input.type == 'text'">
                                    <input type="text" class="input required" :id="'input-' + input.name" @input="warningClear" :placeholder="input.placeholder" autocomplete="off" v-if="input.required"/>
                                    <input type="text" class="input" :id="'input-' + input.name" @input="warningClear" :placeholder="input.placeholder" autocomplete="off" v-else/>
                                </div>
                                <div class="sbi-board-input" v-if="input.type == 'email'">
                                    <input type="text" class="input email required" :id="'input-' + input.name" @input="warningClear" :placeholder="input.placeholder" autocomplete="off" v-if="input.required"/>
                                    <input type="text" class="input email" :id="'input-' + input.name" @input="warningClear" :placeholder="input.placeholder" autocomplete="off" v-else/>
                                </div>
                                <div class="sbi-board-input" v-else-if="input.type == 'number'">
                                    <input type="number" class="input required" :id="'input-' + input.name" @input="warningClear" :placeholder="input.placeholder" autocomplete="off" v-if="input.required"/>
                                    <input type="number" class="input" :id="'input-' + input.name" @input="warningClear" :placeholder="input.placeholder" autocomplete="off" v-else/>
                                </div>
                                <div class="sbi-board-input" v-else-if="input.type == 'phone'">
                                    <input type="number" class="input phone required" :id="'input-' + input.name" @input="warningClear" :placeholder="input.placeholder" autocomplete="off" v-if="input.required"/>
                                    <input type="number" class="input" :id="'input-' + input.name" @input="warningClear" :placeholder="input.placeholder" autocomplete="off" v-else/>
                                </div>
                                <div class="sbi-board-input" v-else-if="input.type == 'checkbox'" :id="'element-' + input.name">
                                    <div class="sbi-checkbox" v-for="(io, ioindex) in input.options" :key="io">
                                        <input type="checkbox" class="input checkbox" :id="'input-' + input.name + '-' + ioindex" :placeholder="input.placeholder" autocomplete="off" :value="io.value" />
                                        <label :for="'input-' + input.name + '-' + ioindex">{{ io.label }}</label>
                                    </div>
                                </div>
                                <div class="sbi-board-input" v-else-if="input.type == 'checkbox-price'" :id="'element-' + input.name">
                                    <div class="sbi-checkbox" v-for="(io, ioindex) in input.options" :key="io">
                                        <input type="checkbox" class="input checkbox" :id="'input-' + input.name + '-' + ioindex" :placeholder="input.placeholder" autocomplete="off" :value="io.value" @change="cpCalculate(input.name, input.discounts);" />
                                        <label :for="'input-' + input.name + '-' + ioindex">{{ io.label }} - Rp{{ number_format(io.class.price, 0, ',', '.') }}</label>
                                        <input type="hidden" class="" :id="'input-price-' + input.name + '-' + ioindex" :placeholder="input.placeholder" autocomplete="off" :value="io.class.price" />
                                    </div>
                                    <div class="sbi-checkbox-discount" :id="'eldisc-' + input.name">
                                        <div class="sct-row">
                                            <div class="sct-param">
                                                <span>Discount</span>
                                            </div>
                                            <div class="sct-value">
                                                <span>Rp0</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-checkbox-total" :id="'eltotal-' + input.name">
                                        <div class="sct-row">
                                            <div class="sct-param">
                                                <span>Total</span>
                                            </div>
                                            <div class="sct-value">
                                                <span>Rp0</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sbi-board-input" v-else-if="input.type == 'radio'" :id="'element-' + input.name">
                                    <div class="sbi-checkbox" v-for="(io, ioindex) in input.options" :key="io">
                                        <input type="radio"  :name="input.name" class="input-radio" :id="'input-' + input.name + '-' + ioindex" :placeholder="input.placeholder" :value="io.value" />
                                        <label :for="'input-' + input.name + '-' + ioindex">{{ io.label }}</label>
                                    </div>
                                </div>
                                <div class="sbi-board-input" v-else-if="input.type == 'file'">
                                    <div class="sbi-description">
                                        <p>{{ input.description }}</p>
                                    </div>
                                    <div class="sbi-file-preview"></div>
                                    <input type="file" class="input required" :id="'input-' + input.name" @input="imgPreview('input-' + input.name)" :placeholder="input.placeholder" v-if="input.required"/>
                                    <input type="file" class="input" :id="'input-' + input.name" @input="imgPreview('input-' + input.name)" :placeholder="input.placeholder" v-else/>
                                </div>
                                <!-- <div class="sbi-board-input" v-else-if="input.type == 'radio' && !input.required" :id="'input-radio-' + input.name">
                                    <div class="sbi-checkbox" v-for="io in input.options" :key="io">
                                        <input type="radio"  :name="input.name" class="input-radio" :id="'input-option-' + input.name + '-' + io.id" :placeholder="input.placeholder" :value="io.label" />
                                        <label :for="'input-option-' + input.name + '-' + io.id">{{ io.label }}</label>
                                    </div>
                                </div> -->
                                <div class="sbi-board-input" v-else-if="input.type == 'date'">
                                    <!-- <div class="date-cover">DD-MM-YYYY</div> -->
                                    <input type="date" format="dd-mm-yyyy" class="input required" :id="'input-' + input.name" @input="warningClear" :placeholder="input.placeholder"/>
                                </div>
                                <div class="sbi-board-warning warning" :id="'warning-' + input.name"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sbi-footer">
                    <div class="sbi-board-button" v-if="!eventLoader">
                        <button @click="formSubmit">Submit</button>
                    </div>
                    <div class="sbi-board-button" v-else>
                        <button>Loading...</button>
                    </div>
                </div>
            </div>
            <div class="section-body-inner" v-else>
                <div class="sbi-body sbi-body-closed">
                    <p>Pendaftaran belum dibuka atau sudah ditutup</p>
                </div>
            </div>
        </div>
    </section>
    <div class="eventnotif" id="eventnotif">
        <div class="eventnotif-head">
            <div class="notification-close">
                <span @click="hideEventnotif, clickElement('webinar-url')">x</span>
            </div>
            <h5 class="eventnotif-title" id="eventnotif-title"></h5>
        </div>
        <div class="eventnotif-body">
            <div class="eventnotif-message" id="eventnotif-message"></div>
            <div class="eventnotif-link" v-if="webinar">
                <a :href="'https://wa.me/' + contact.phone + '?text=' + contact.text" target="_blank" id="webinar-url" @click="hideEventnotif" v-for="contact in webinar.contacts" :key="contact">
                    <div class="eventnotif-link-inner">
                        <div class="eli-image">
                            <img src="../../assets/icons/waicon.png" alt="whatsapp"/>
                        </div>
                        <div class="eli-text">
                            <span> {{ contact.name }}</span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { computed } from "@vue/runtime-core";
    import { hideEventnotif, formSubmit, warningClear, imgPreview, cpCalculate } from '@/functions/event';
    import { clickElement, number_format } from '@/functions/general';
    import { useEventStore } from '@/stores/event';
    const eventForm = computed(() => useEventStore().eventForm);
    const webinar = computed(() => useEventStore().webinar);
    const eventLoader = computed(() => useEventStore().eventLoader);
    const userAgent = computed(() => useEventStore().userAgent);
    useEventStore().setEventForm(useRoute().params.slug);
    // function cpCalculate(name){
    //     const el = document.getElementById('element-' + name);
    //     const de = document.getElementById('eldisc-' + name);
    //     const et = document.getElementById('eltotal-' + name);
    //     const discount = {type: 'flat', value: 50000, min_qt: 3};
    //     var checked = 0;
    //     var discval = 0;
    //     var total = 0;
    //     for(var i = 0; i < el.children.length; i++){
    //         if(el.children[i].children[0].checked){
    //             total = parseInt(total) + parseInt(el.children[i].children[2].value);
    //             checked = parseInt(checked) + 1;
    //         }
    //     }
    //     if(checked >= discount.min_qt){
    //         if(discount.value > 0){
    //             if(discount.type == 'percent'){
    //                 if(discount.value <= 100){
    //                     discval = (total / 100) * discount.value;
    //                     total = total - discval;
    //                 }
    //             }else if(discount.type == 'flat'){
    //                 discval = discount.value;
    //                 total = total - discval;
    //             }
    //         }
    //     }
    //     de.children[0].children[1].children[0].innerHTML = 'Rp' + number_format(discval, 0, ',', '.');
    //     et.children[0].children[1].children[0].innerHTML = 'Rp' + number_format(total, 0, ',', '.');
    // }
</script>

<style scoped>
    .eventnotif{
        display: none;
        position: fixed;
        top: 15%;
        left: 10%;
        right: 10%;
        background: rgb(65, 166, 233);
        color: var(--white);
        width: 80%;
        min-height: 50px;
        max-height: 50%;
        overflow: auto;
        border-radius: 5px;
        padding: 0.5rem;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        user-select: none;
        animation-name: animatenotification;
        animation-duration: 0.5s;
        z-index: 1000000;
    }
    .eventnotif-body p{
        color: var(--white);
    }
    .eventnotif-message{
        text-align: center;
    }
    .eventnotif-link{
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .eventnotif-link a{
        text-decoration: none;
        color: var(--grey);
        background: var(--white);
        padding: 0.5rem 2rem 0.5rem 2rem;
        border-radius: 5px;
        transition: 0.2s;
        margin: 1rem;
    }
    .eventnotif-link a:hover{
        background: var(--smoke);
    }
    .eventnotif-link-inner{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid green; */
    }
    .eli-image{
        height: 100%;
        display: flex;
        align-items: center;
        /* border: 1px solid navy; */
    }
    .eli-image img{
        max-width: 20px;
        /* border: 1px solid green; */
    }
    .eli-text{
        padding: 0 0 0 0.5rem;
    }
    .section-head{
        width: 100%;
    }
    .section-head-image img{
        width: 100%;
    }
    .section-body{
        display: flex;
        justify-content: center;
        width: 100%;
        background: rgb(116, 169, 238);
    }
    .section-body-inner{
        width: 100%;
        max-width: 1200px;
        padding: 2rem 1rem 2rem 1rem;
    }
    .sbi-head-board{
        width: 100%;
        background: #ffffff;
        color: grey;
        padding: 1rem;
        border-radius: 5px;
        margin-bottom: 1rem;
    }
    .sbi-board-main h1{
        font-size: 1.5rem;
        transition: 0.2s;
    }
    .sbi-board-main p{
        font-size: 1rem;
        transition: 0.2s;
    }
    .sbi-body{
        background: #ffffff;
        padding: 1rem 1rem 1rem 1rem;
        border-radius: 5px;
    }
    .sbi-body-closed p{
        text-align: center;
        color: red;
    }
    .sbi-body-board{
        width: 100%;
        color: grey;
        margin-bottom: 2rem;
    }
    .sbi-board-label{
        margin-bottom: 0.5rem;
        color: #000000;
        font-weight: 700;
    }
    .sbl-sign{
        color: rgb(255, 0, 0);
        padding-left: 0.5rem;
    }
    .sbi-board-input{
        position: relative;
        width: 50%;
        border-bottom: 1px solid lightgrey;
        padding-bottom: 0.5rem;
        transition: 0.2s;
    }
    .sbi-board-input input{
        width: 100%;
        outline: none;
        border: none;
    }
    .sbi-board-input input::-webkit-outer-spin-button,
    .sbi-board-input input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .sbi-board-input input[type=number] {
        -moz-appearance: textfield;
    }
    .date-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 90%;
        height: 27px;
        background: #ffffff;
        /* border: 1px solid green; */
        user-select: none;
    }
    .sbi-checkbox{
        width: 100%;
        display: flex;
        text-align: left;
        /* border: 1px solid green; */
        margin-bottom: 0.5rem;
    }
    .sbi-checkbox input{
        width: 10%;
    }
    .sbi-checkbox label{
        width: 80%;
    }
    .sbi-checkbox-discount{
        padding: 1rem 0 0 0;
    }
    .sbi-checkbox-total{
        width: 100%;
        font-weight: 700;
        padding: 1rem 0 0 0;
    }
    .sct-row{
        display: flex;
    }
    .sct-param{
        width: 50%;
        display: flex;
        justify-content: right;
    }
    .sct-value{
        width: 50%;
        display: flex;
        justify-content: right;
        border-top: 1px solid grey;
    }
    .sbi-board-warning{
        color: red;
        font-style: italic;
    }
    .sbi-footer{
        padding: 1rem 0 1rem 0;
    }
    .sbi-board-button{
        display: flex;
        transition: 0.2s;
    }
    .sbi-board-button button{
        border: none;
        border-radius: 5px;
        background: rgb(255, 255, 255);
        color: #000000;
        padding: 0.5rem;
        min-width: 200px;
        font-weight: 700;
        transition: 0.2s;
    }
    .sbi-board-button button:hover{
        background: whitesmoke;
    }
    @media only screen and (max-width: 1500px){
        .eventnotif{
            right: 5%;
        }
    }
    @media only screen and (max-width: 750px){
        /* .notification{
            right: 2.5%;
        } */
        .sbi-board-main h1{
            font-size: 1.1rem;
            text-align: center;
        }
        .sbi-board-main p{
            font-size: 0.9rem;
        }
        .sbi-board-input{
            width: 100%;
        }
        .sbi-board-button{
            justify-content: right;
        }
        .sbi-board-button button{
            min-width: unset;
            padding: 0.5rem 2rem 0.5rem 2rem;
        }
    }
</style>